@import "../../css/css3-mixins";
@import "../../css/constants.scss";



/* ------------------------------------------------------------------------------------------------------------------ */
/* ----------------------------------------------- OPTION TABLE ----------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------ */
gh-option-table {
  display: block;
  position: relative;
    width: 100%;
    font-size: 14px;

    .item {
        @include flex(1,1,0);
        max-height: 100%;

        &.icon {
            min-width: 32px;
            max-width: 32px;
            cursor: pointer;

            &.move {
                cursor: move;
            }
        }
    }

    .thead,
    edit-form-field-setting,
    ul > li {
        @include display-flex();
        @include flex-direction('row');
        flex-wrap: nowrap;
        position: relative;

        & > * {
            align-self: center;
        }
    }

    edit-form-field-setting {
        border-radius: 10px;
        border: 1px solid $blue;
        position: absolute;
        z-index: 100;
        background-color: $white;
        width: 100%;
        height: 100%;
        top: 0;
    }

    .thead {
        color: $blue;
        font-size: 16px;
        text-align: center;
    }

    ul.option-list {
        margin-bottom: 10px;
        padding-top: 10px;

        li {
            height: 45px;
            text-align: center;

            option-setting {
                text-align: left;
                margin: 0 2px;
            }

            &:not(:last-child) {
                border-bottom: 1px solid $grey;
            }

            &:hover {
                background-color: rgba(8, 146, 210, 0.14)!important;

                .icon.move {
                    svg {
                        path {
                            fill: $blue;

                        }
                    }
                }
            }

            &.dndPlaceholder {
                display: block;
                @include background-hex($blue, 0.14);
                border-radius: 0;
                width: 100%;
                height: 45px;
                padding: 0;
                margin: 0;
            }
        }
    }

    .add-option {
        position: relative;
    }
}
.add-option input{
  padding-top: 0;
}
/* ------------------------------------------------------------------------------------------------------------------ */
/* ----------------------------------------------------- OPTION ----------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------ */
gh-option {
  display: block;
  position: relative;
}
body.gudhub  option-setting input,body.gudhub  option-setting input:focus{
  padding-top: 0;
}
