@import "../../../css/css3-mixins";
@import "../../../css/constants.scss";

/* ------------------------------------------------------------------------------------------------------------------ */
/* ----------------------------------------------- GH-TEXT-EDITOR --------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------ */
gh-text-editor {
    display: block;
    position: relative;
    transition: 0.3s ease-out all;

    &.editorTrue{
        border-left: 2px solid #d9e0e3;
        padding-left: 10px;
        margin-top: 1em;

        &:hover {
            border-left: 2px solid rgba(8, 147, 210, 1);
            cursor: pointer;

            div.contentPage {
                border: 1px solid $blue;
            }
        }
    }

    & > div.mce-tinymce.mce-container.mce-panel {
        border: 0;
    }

    table {
        td {
            vertical-align: top;
        }
    }

    div.contentPage {
        font-weight: 500;
        border: 1px solid $white;
        box-shadow: 0 0 5px 0 rgba(0,0,0,0.15);
    }
}


/* -------------------------- TEXT EDITOR ----------------------------------- */
gh-text-editor {
  display: block;
  position: relative;
}

/* ------------------------------ TAG --------------------------------------- */
gh-tag {
  display: block;
  position: relative;
}
