@import "../../css/css3-mixins";
@import "../../css/constants.scss";


/* ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------ */
/* --------------------------------------------------- GH-FILTER ---------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------ */
gh-filter {
    display: block;
    width: 100%;
    .gh-filter-container-full {
        width: 100%;
    }

    .filter-content {
        overflow: hidden;
        max-height: 0;
        @include user-select( 'none');
        @include transition( 'max-height 0.5s ease');

        &.open {
            max-height: 10000px;
            overflow: visible;
        }
    }

    .filter-item {
        position: relative;
        margin: 10px 0;

        .filter-wrap {
            .filter-name {
                @include display-flex();
                @include flex-direction( 'row');
                @include flex-align-items( 'center');
                border-bottom: 1px solid $blue;

                .expand_button {
                    min-width: 22px;
                    max-width: 22px;

                    &.open {
                        @include transform( 'rotate(180deg)');
                    }
                }

                .cross-button {
                    min-width: 22px;
                    max-width: 22px;
                }

                p {
                    height: 100%;
                    font-weight: 300;
                    font-size: 18px;
                    width: 100%;
                    color: $blue;
                    padding: 5px 0;
                }
            }
        }
    }
    .layout, .layout-column, .layout-row{
        display: inline-block;
        margin: 0px 5px;
    }
    .filter-input{
        width: 100%
    }
    .gh-dropdown{
        margin: 0;
        padding: 0;
        vertical-align: -webkit-baseline-middle;
    }
    .layout-margin{
        margin: 8px 0;
    }
    .filter_value input {
        margin-bottom: 10px;
    }
    .add_filter-btn {
        margin-top: 10px;
    }
}
