gh-automation {
  display: block;
  min-height: 35vh;
  height: calc(100vh - 121px);
  width: 100vw;

 gh-element[element-type='additional_settings']{
   float: left;
 }

  .add-node-button{
    display: inline-block;
    margin: 5px 5px;
    padding: 5px;
    border: 1px solid #607D8B;
    cursor: pointer;
  }

  .gh-automation-apply-button{
    float: left;
  }

  .gh-node-content{
    height: 140px;

    gh-automation-settings {
      display: inline-block;
      text-align: left;
    }

    .gh-delete-node {
      display: inline-block;
      text-align: left;
      margin-left: 95px;
    }
    .active {
        path {
          animation-name: blinkRun;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          @keyframes blinkRun {
            0% {
              fill: #d32f2f;
            }
            50% {
              fill: #fff;
            }
            100% {
              fill: #d32f2f;
            }
          }
        }
    
    }
    .automation-settings-icon{
      display: inherit;
      text-align: center;
    }

    .node-notes{
      width: 140px;
      height: 50px;
      display: block;
      overflow: hidden;
      color: #ffffff;
    }
  }

  .node{
    &.trigger, &.gh-element-node, &.quiz-node, &.smartinput{
        background: #2196F3;
        &:hover{
          background: #03A9F4;
        }
    }


    &.selected.trigger, &.selected.gh-element-node, &.selected.quiz-node, &.selected.smartinput{
      background:#00bcd4;
      border-color:#009688;
    }

    .socket{
      &.items-array{
        background:#8bc34a;
      }

      &.field-value {
        background: #29b6f6;
      }
      
      &.object-value {
        background: #ab47bc;
      }
    }

  }


}
