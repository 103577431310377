@import "../../css/constants.scss";
/* ============================ GH_VIEW STYLE TEMPLATES EDITOR ============================ */
gh-view {
    display: flex;
    height: 100%;
    width: 100%;

    .ghViewContainer.ghEditor {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        gh-element{
            margin-top: -1px;
            border: 1px solid $light-blue;
            min-height: 40px;
            width: 100%;

        }

        .ghViewNavigation {
            display: flex;
            justify-content: space-between;
            padding-bottom: 5px;

            .ghViewNameInput {
                height: auto;
                font-weight: 300;
                padding: 7px 14px;
            }

            .ghViewAdd {
                width: 28px;
                height: 28px;
                cursor: pointer;
                align-items: center;
                display: flex;
            }
        }

        .ghViewContainerList {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            background-color: #edeff0;

            // Tabs
            .gh_tabs {
                float: left;
                width: 100%;
                height: 100%;
                padding: 4px;
                border: 1px dashed #d9d9d9;
                transition: all .5s;

                &:hover {
                    border-color: #338fff;
                }

                .gh_tabs_block {
                    height: 100%;

                    .gh_tabs_align {
                        display: flex;

                        .gh_tabs_block_nav {
                            .gh_tabs_block_nav_btn {
                                color: $dark-grey;
                                font-size: 18px;
                                font-weight: 300;
                                line-height: 1;
                                text-transform: uppercase;
                                display: inline-flex;
                                padding: 5px;
                                margin-right: 30px;
                                align-items: center;
                                transition: all .3s;
                                cursor: pointer;
                                float: left;

                                input {
                                    font-size: 18px;
                                    color: $dark-grey;
                                    font-weight: 300;
                                    line-height: 1;
                                    text-transform: uppercase;
                                    max-width: 100px;
                                    padding: 5px 0 4px;
                                    border: none;

                                    &:focus {
                                        color: #0893d2;
                                    }
                                }

                                &:last-child {
                                    margin-right: 0;
                                }

                                &.--active_tab {
                                    color: #0893d2;
                                }

                                .gh_tabs_block_nav_btn_icon {
                                    display: none;
                                    margin: 0 3px;

                                    &.--delete_tab {
                                        opacity: .4;
                                        display: flex;
                                    }

                                    &.--add_tab {
                                        display: flex;
                                    }
                                }

                                .gh_tabs_block_nav_btn_title {
                                    display: none;
                                }

                                &:hover {
                                    color: #0893d2;

                                    > .gh_tabs_block_nav_btn_icon.--delete_tab {
                                        opacity: 1;
                                    }
                                }
                            }
                        }

                        .gh_tabs_block_list {
                            float: left;
                            // overflow-y: auto;
                            -webkit-overflow-scrolling: touch;
                            width: 100%;
                            height: calc(100% - 40px);
                            min-height: 40px;
                            

                            .gh_tabs_block_list_item {
                            }
                        }
                    }
                }
            }

            // Flex
            .gh_flex {
                overflow: hidden;
                height: 100%;
                width: 100%;
                padding: 4px;
                background: #edeff0;
                border: 1px dashed #d9d9d9;
                transition: all .5s;
                position: relative;

                &:hover {
                    border-color: #338fff;
                }

                &.bottom_shadow_on{
                    .gh_flex_block_list{
                        position: relative;
    
                        &::after{
                            content:'';
                            position: absolute;
                            bottom: 5px;
                            left: 0;
                            width: 100%;
                            height: 3px;
                            box-shadow: -1px 2px 4px rgba(0, 0, 128, 0.1);
                            border-bottom: 1px solid #e7e7ee;
                        }
                    }
                }

                .gh_flex_block {
                    height: 100%;
                    width: 100%;

                    .gh_flex_block_list {
                        width: 100%;
                        display: flex;
                        height: calc(100% - 40px);
                        min-height: 40px;

                        .gh_flex_block_list_item {
                        }
                    }
                }
            }
           

            // Float
            .gh_float {
                overflow-y: auto;
                overflow-x: hidden;
                -webkit-overflow-scrolling: touch;
                box-sizing: border-box;
                float: left;
                padding: 4px;
                background: #fff;
                border: 1px dashed #d9d9d9;
                transition: all .5s;
                position: relative;

                &:hover {
                    border-color: #338fff;
                }


                .gh_float_block {
                    height: 100%;
                    width: 100%;

                    .gh_float_block_list {
                        width: 100%;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;
                        float: left;
                        display: inline-block;
                        flex-direction: row;
                        flex-wrap: wrap;
                        align-content: flex-start;
                        position: relative;
                        height: calc(100% - 40px);
                        min-height: 40px;

                        .gh_float_block_list_item {
                        }
                    }
                }
                &.margin_top{
                    margin-top: 40px;
                }
                &.margin_bottom{
                    margin-bottom: 40px;
                }


                &.horizont_scroll{
                    // overflow-x: auto;
                    // overflow-y: hidden;
                    -webkit-overflow-scrolling:touch;
                    scroll-behavior: smooth;

                    .gh_float_block_list{
                        width: auto;
                        display: flex;
                        flex-wrap: nowrap;
                        overflow-x: auto;
                        overflow-y: hidden;
                        scroll-behavior: smooth;
                        -webkit-overflow-scrolling:touch;
                        
                        p,span{
                            white-space: nowrap;
                        }
                    }
    
                    & > .float_block_template_repeat_form{
                        width: auto;
                        display: flex;
                        flex-wrap: nowrap;

                        p,span{
                            white-space: nowrap;
                        }
                    }
                }
            }

            // Element
            gh-element{
                float: left;
                flex: 0 1 auto;
                justify-content: flex-start;
                align-items: flex-start;
                display: flex;
                width: 100%;
                padding: 10px 0;
                .field-wrap-name{
                  padding-right: 14px;
                  display: inline-block;
                  // line-height: 1em;
                  span.field-block-name{
                      min-width: 100px;
                      max-width: 100px;
                      white-space: nowrap;
                      text-align: right;
                      display: flex;
                      justify-content: flex-end;
                      position: relative;
                  }

                  span.field-name {
                      user-select: none;
                      padding: 0 10px 0 0;
                      font-size: 12px;
                      font-weight: 500;
                      color: #bbbcc5;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      line-height: 1em;
                      display: inline-block;
                      white-space: normal;
                  }
                }
            }

            gh-element[data-position*=left] {
                float: left;
                width: auto;
            }

            gh-element[data-position*=right] {
                float: right;
                width: auto;
            }

            gh-element[data-position*=beetwen] {
                width: 100%;
            }

            gh-element[element-type=table] {
                height: 100%;
            }
        }

        .dndDraggingSource {
            display: none !important;
        }

        .dndPlaceholder {
            background: #0893d2;
            opacity: .2;
            padding: 0;
            margin: 0;
            width: 100%;
            // border-radius: 25px;
            min-height: 40px;
            float: left;
        }
    }
}

/* ============================ EDIT TEMPLATE SERVICE ============================ */
.edit_template_container{
    height: 100%;

    .menu{
        display: flex;
        justify-content: space-between;

        ul{
            display: inline-flex;
            align-items: center;
            flex-wrap: wrap;

            li{
                display: inline-flex;
                padding: 0 5px;

                &:hover{
                    .btn_view_delete{
                        opacity: .4;
                    }
                }

                .btn_view_delete{
                    display: inline-flex;
                    opacity: 0;
                    cursor: pointer;

                    &:hover{
                        opacity: 1;
                    }
                }

                .btn_save{
                    background-color: #0893d2;
                    color: #fff;
                    font-weight: 200;
                    border: 1px solid #0893d2;
                    padding: 8px 13px;
                    border-radius: 50px;
                }

                .btn_view{
                    border: none;
                    background: transparent;
                    font-size: 16px;
                    font-weight: 200;
                    color: #6a6a6a;

                    &.active_view{
                        color: #0893d2;
                    }
                }

                .btn_add{
                    border: none;
                    background: transparent;
                    font-size: 18px;
                    font-weight: 200;
                    color: #0893d2;
                }
            }
        }
    }

    .edit_template_container_block{
        display: flex;
        height: calc(100% - 34px);

        .edit_template_container_block_tabs{
            height: 100%;
            width: 335px;
            overflow: hidden;

            .edit_template_container_block_tabs_navigation{
                display: flex;

                .edit_template_container_block_tabs_navigation_button{
                    border-bottom: 2px solid transparent;
                    padding: 7px 14px;
                    user-select: none;

                    &.gh-active-tab{
                        border-color: #0893d2;
                    }
                }
            }

            .edit_template_container_block_tabs_container{
                height: calc(100% - 32px);

                .edit_template_container_block_tab{
                    display: none;
                    height: 100%;
                    flex-wrap: wrap;

                    &.activeTab{
                        display: flex;
                    }

                    .edit_template_container_block_tab_search{
                        display: flex;
                        width: 100%;
                        margin: 10px;
                        justify-content: flex-start;
                        align-items: flex-start;

                        input{
                            height: auto;
                        }
                    }

                    .edit_template_container_block_tab_elements_block{
                        height: calc(100% - 46px);
                        width: 100%;
                        padding: 0 10px;
                        overflow-y: auto;
                        -webkit-overflow-scrolling: touch;

                        .edit_template_container_block_tab_element{
                            display: flex;
                            width: 100%;
                            margin: 10px 0;
                            background-color: #fff;
                            border: 1px solid transparent;
                            box-shadow: 0 1px 7px rgba(0,0,0,.2);
                            border-radius: 10px;
                            padding: 10px;
                            justify-content: space-between;
                            align-items: center;

                            &:hover{
                                border-color: #0893d2;
                            }

                            .edit_template_container_block_tab_element_icon_title{
                                display: flex;
                                align-items: center;

                                .edit_template_container_block_tab_element_icon{
                                    margin-right: 25px;
                                }
                            }

                            .edit_template_container_block_tab_element_existing_icon{
                                position: relative;

                                .edit_template_container_block_tab_element_existing_settings{
                                    display: none;
                                    background-color: #fff;
                                    padding: 5px 0;
                                    z-index: 10;
                                    position: absolute;
                                    box-shadow: 0 0 5px rgba(121, 121, 121, 0.61);
                                    min-width: 200px;
                                    top: 0;
                                    right: 0;

                                    li{
                                        padding: 3px;
                                        cursor: pointer;
                                        display: flex;
                                        align-items: center;

                                        span{
                                            margin-right: 5px;
                                        }

                                        &:hover{
                                            background: rgba(8, 147, 210, 0.14);
                                        }
                                    }
                                }

                                &:hover .edit_template_container_block_tab_element_existing_settings{
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }

        .edit_template_container_block_views{
            height: 100%;
            width: 100%;
            overflow: hidden
        }
    }
}

/* ============================ pencilContainerSettings ============================ */
.ghEditor{
    .edit_template_container_form{
        display: flex;
        justify-content: flex-end;
        min-height: 32px;
        min-width: 35px;

        .title_block{
            width: 100%;
        }

        .title_block, .icon_block{
            display: none;
        }

        .edit_template_container_form_icon_title{
            display: flex;
            width: 100%;
            font-weight: 200;
            font-size: 34px;
            line-height: 40px;
            color: #0893d2;
            overflow: hidden;
            align-items: flex-end;
        }

        input{
            font-size: 28px;
            font-weight: 200;
            color: #0893d2;
            width: 100%;
        }

        .icon_pencil_container{
            display: none;
            position: absolute;
            right: 5px;
            top: 5px;

            &:hover .icon_container{
                display: block;
            }

            .icon_container{
                display: none;
                position: fixed;
                background-color: #fff;
                padding: 5px 0;
                z-index: 10;
                box-shadow: 0 0 5px rgba(121, 121, 121, .61);
                min-width: 200px;
                transform: translate(-90%, -1%);

                li{
                    cursor: pointer;
                    padding: 3px;
                    display: flex;
                    position: relative;
                    transition: all .3s;

                    &:hover{
                        background: rgba(8, 147, 210, .14);
                    }

                    div{
                        display: flex;
                        align-items: center;
                        width: 100%;

                        span{
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }

    // Flex
    .gh_flex{
        &:hover{
            > .gh_flex_block > .edit_template_container_form .icon_pencil_container{
                display: block;
            }
        }
    }

    // Float
    .gh_float{
        &:hover{
            > .gh_float_block > .edit_template_container_form .icon_pencil_container{
                display: block;
            }
        }

        &.gh_title{
            > .gh_float_block > .gh_float_block_list{
                height: calc(100% - 44px) !important;
            }

            > .gh_float_block > .edit_template_container_form{
                .title_block{
                    display: block;
                    border-bottom: 1px solid #0893d2;
                }
            }
        }

        &.gh_icon{
            > .gh_float_block > .edit_template_container_form{
                .icon_block{
                    display: flex;
                }
            }
        }
    }

    // Tabs
    .gh_tabs{
        &:hover{
            > .gh_tabs_block > .edit_template_container_form .icon_pencil_container{
                display: block;
            }
        }

        &.gh_title{
            > .gh_tabs_block > .gh_tabs_align > .gh_tabs_block_nav > .gh_tabs_block_nav_btn > .gh_tabs_block_nav_btn_title{
                display: inline !important;
            }
        }

        &.gh_icon{
            > .gh_tabs_block > .gh_tabs_align > .gh_tabs_block_nav > .gh_tabs_block_nav_btn > .gh_tabs_block_nav_btn_icon{
                display: flex !important;
            }
        }

        &.gh_nav_tabs_top{
            > .gh_tabs_block > .gh_tabs_align{
                flex-direction: column;
            }

            > .gh_tabs_block > .gh_tabs_align > .gh_tabs_block_nav{
                width: 100%;
                border-bottom: 1px solid #cacbd1;

                .gh_tabs_block_nav_btn{
                    &.--active_tab{
                        box-shadow: inset 0 -3px 0 0 #0893d2;
                    }
                }
            }
        }

        &.gh_nav_tabs_bottom{
            > .gh_tabs_block > .gh_tabs_align{
                flex-direction: column-reverse;
            }

            > .gh_tabs_block > .gh_tabs_align > .gh_tabs_block_nav{
                width: 100%;
                border-top: 1px solid #cacbd1;

                .gh_tabs_block_nav_btn{
                    &.--active_tab{
                        box-shadow: inset 0 3px 0 0 #0893d2;
                    }
                  
                }
            }
        }

        &.gh_nav_tabs_left{
            > .gh_tabs_block > .gh_tabs_align{
                flex-direction: row;
            }

            > .gh_tabs_block > .gh_tabs_align > .gh_tabs_block_nav{
                border-right: 1px solid #cacbd1;
                max-width: 200px;
                display: flex;
                flex-direction: column;

                .gh_tabs_block_nav_btn{
                    margin-right: 0 !important;
                    flex-direction: row-reverse;

                    &.--active_tab{
                        box-shadow: inset -3px 0 0 0 #0893d2;
                    }

                    input{
                        text-align: right;
                    }
                }
            }
        }

        &.gh_nav_tabs_right{
            > .gh_tabs_block > .gh_tabs_align{
                flex-direction: row-reverse;
            }

            > .gh_tabs_block > .gh_tabs_align > .gh_tabs_block_nav{
                border-left: 1px solid #cacbd1;
                max-width: 200px;
                display: flex;
                flex-direction: column;

                .gh_tabs_block_nav_btn{
                    margin-right: 0 !important;
                    flex-direction: row;

                    &.--active_tab{
                        box-shadow: inset 3px 0 0 0 #0893d2;
                    }
                }
            }
        }
    }
}

.ghForm{
    // Float
    .gh_float{
        &.gh_title{
            > .template_icon_title_block_form > .template_title_form{
                display: block !important;
            }
        }

        &.gh_icon{
            > .template_icon_title_block_form > .template_icon_form{
                display: flex !important;
            }
        }

        &.gh_title, &.gh_icon{
            > .float_block_template_repeat_form{
                height: calc(100% - 41px) !important;
            }
        }
    }

    // Tabs
    .gh_tabs{
        &.gh_title{
            > .gh_tabs_block_nav > .gh_tabs_block_nav_btn > .gh_tabs_block_nav_btn_title{
                display: inline !important;
            }
        }

        &.gh_icon{
            > .gh_tabs_block_nav > .gh_tabs_block_nav_btn > .gh_tabs_block_nav_btn_icon{
                display: flex !important;
            }
        }

        &.gh_nav_tabs_top{
            flex-direction: column;

            > .gh_tabs_block_nav{
                width: 100%;
                // border-bottom: 1px solid #cacbd1;
                display: flex;
                overflow-x: auto;
                overflow-y: hidden;
                flex-wrap: nowrap;
                -webkit-overflow-scrolling: touch;
                position: relative;

                &::after{
                    content: "";
                    display: block;
                    width: 100%;
                    height: 1px;
                    background: $grey;
                    left: 0;
                    bottom: 7px;
                    position: absolute;
                }


                span{
                    white-space: nowrap;
                }

                .gh_tabs_block_nav_btn{
                    &.--active_tab{
                        position: relative;

                        &::after{
                            content: "";
                            display: block;
                            width: calc(100% - 25px);
                            height: 4px;
                            background: $blue;
                            left: 0;
                            bottom: 7px;
                            position: absolute;
                        }
                       
                    }
                }
            }
        }

        &.gh_nav_tabs_bottom{
            flex-direction: column-reverse;

            > .gh_tabs_block_nav{
                width: 100%;
                border-top: 1px solid #cacbd1;

                .gh_tabs_block_nav_btn{
                    &.--active_tab{
                        box-shadow: inset 0 3px 0 0 #0893d2;
                    }
                      
                    &:before{
                        bottom: auto;
                        top: -1px;
                    }
                }
            }
        }

        &.gh_nav_tabs_left{
            flex-direction: row;

            > .gh_tabs_block_nav{
                border-right: 1px solid #cacbd1;
                max-width: 200px;
                display: flex;
                flex-direction: column;


                .gh_tabs_block_nav_btn{
                    margin-right: 0 !important;
                    flex-direction: row-reverse;

                    &.--active_tab{
                        box-shadow: inset -3px 0 0 0 #0893d2;
                    }

                    input{
                        text-align: right;
                    }
                    &:before{
                        bottom: 0 !important;
                    }
                }
            }
        }

        &.gh_nav_tabs_right{
            flex-direction: row-reverse;

            > .gh_tabs_block_nav{
                border-left: 1px solid #cacbd1;
                max-width: 200px;
                display: flex;
                flex-direction: column;

                .gh_tabs_block_nav_btn{
                    margin-right: 0 !important;
                    flex-direction: row;

                    &.--active_tab{
                        box-shadow: inset 3px 0 0 0 #0893d2;
                    }
                    &:before{
                        bottom: 0 !important;
                    }
                }
            }
        }
    }
}

/* ============================ SIZES CONTAINERS ============================ */
@for $i from 1 through 12{
    .gh_width_percent{
        &.gh_col_width_#{$i}{
            width: 100% / 12 * $i;
        }
    }

    .gh_height_percent{
        &.gh_col_height_#{$i}{
            height: 100% / 12 * $i !important;
        }
    }

    .gh_width_px{
        &.gh_col_width_#{$i}{
            max-width: 50px * $i;
            min-width: 50px * $i;
        }
    }

    .gh_height_px{
        &.gh_col_height_#{$i}{
            max-height: 60px * $i;
            min-height: 50px * $i;
        }
    }

    .ghEditor{
        .gh_height_px{
            &.gh_col_height_#{$i}{
                max-height: 100px + 50px * $i;
                min-height: 100px + 50px * $i;
            }
        }
    }
}

.ghEditor{
    .gh_height_px{
        .gh_flex_block .gh_flex_block_list gh-element{
            width: auto;
            padding: 0;
        }

        .dndPlaceholder{
            width: 100px !important;
            height: 100%;
        }
    }
}

.ghForm{
    .gh_height_px{
        .gh_float .float_block_template_repeat_form gh-element{
            width: auto;

        }
    }
}

@media (max-width: 768px) {
    // New Sizes for Adaptive
    @for $i from 1 through 12{
        .gh_float{
             .gh_tabs_block_list_item > .gh_col_width_#{$i},
            > .float_block_template_repeat_form > .gh_col_width_#{$i}{
                width: 100% !important;
            }
        }
    }
}

/* ============================ GH_VIEW STYLE TEMPLATES FORM ============================ */
gh-view{
    .ghViewContainer.ghForm{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        font-weight: 300;

        .gh_flex{
            height: 100%;
            padding: 0 5px;
            display: flex;
            overflow-y: auto;
            scroll-behavior: smooth;
            //-webkit-overflow-scrolling: touch;
            &.bottom_shadow_on{
                position: relative;
            
                &::after{
                    content:'';
                    position: absolute;
                    bottom: 5px;
                    left: 0;
                    width: 100%;
                    height: 3px;
                    box-shadow: -1px 2px 4px rgba(0, 0, 128, 0.1);
                    border-bottom: 1px solid #e7e7ee;
                }
                
            }
        }

        .gh_tabs {
            float: left;
            width: 100%;
            height: 100%;
            padding: 5px;
            display: flex;

            .gh_tabs_block_nav {
                .gh_tabs_block_nav_btn {
                    color: $dark-grey;
                    font-size: 18px;
                    font-weight: 300;
                    line-height: 1;
                    text-transform: uppercase;
                    display: inline-flex;
                    padding: 5px 30px 15px 5px;
                    align-items: center;
                    transition: all .3s;
                    cursor: pointer;
                    float: left;
                    position: relative;

                    &::before{
                        content: "";
                        display: block;
                        width: 100%;
                        height: 1px;
                        background: $grey;
                        left: 0;
                        bottom: 7px;
                        position: absolute;
                    }

                    &:last-child {
                        // padding-right: 0;

                        &::after{
                            width: 100%;
                        }
                    }

                    &.--active_tab {
                        color: #0893d2;
                    }

                    .gh_tabs_block_nav_btn_icon {
                        display: none;
                        margin: 0 3px;
                    }

                    .gh_tabs_block_nav_btn_title {
                        display: none;
                    }

                    &:hover {
                        color: #0893d2;
                    }
                }
            }

            .gh_tabs_block_list {
                float: left;
                // overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                width: 100%;
                height: 100%;
                position: relative;
                overflow-y: auto; 

                .gh_tabs_block_list_item {
                    height: 100%;
                    width: 100%;
                    float: left;
                }
            }
        }

        .gh_float{
            // overflow-y: auto;
            // overflow-x: hidden;
            -webkit-overflow-scrolling: touch;
            padding: 0 5px;
            box-sizing: border-box;
            float: left;
            display: flex;
            flex-wrap: wrap;

            .template_icon_title_block_form{
                display: flex;
                width: 100%;
                font-weight: 200;
                font-size: 34px;
                line-height: 40px;
                color: #0893d2;
                overflow: hidden;
                align-items: flex-end;

                .template_icon_form{
                    display: none;
                }

                .template_title_form{
                    display: none;
                    border-bottom: 1px solid #0893d2;
                    width: 100%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }

            .float_block_template_repeat_form{
                width: 100%;
                // overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                height: 100%;
                display: inline-block;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: flex-start;
                position: relative;
              }
            
            &.margin_top{
                margin-top: 40px;
            }
            &.margin_bottom{
                margin-bottom: 40px;
            }

            &.horizont_scroll{
                overflow-x: auto;
                overflow-y: hidden;
                scroll-behavior: smooth;
                -webkit-overflow-scrolling:touch;
                

                .gh_float_block_list{
                    width: auto;
                    display: flex;
                    flex-wrap: nowrap;
                    overflow-x: auto;
                    overflow-y: hidden;
                    scroll-behavior: smooth;
                    -webkit-overflow-scrolling:touch; 

                    p,span{
                        white-space: nowrap;
                    }
                }

                & > .float_block_template_repeat_form{
                    width: auto;
                    display: flex;
                    flex-wrap: nowrap;

                    p,span{
                        white-space: nowrap;
                    }
                }
            }
        }

        gh-element{
            float: left;
            flex: 0 1 auto;
            justify-content: flex-start;
            align-items: flex-start;
            display: flex;
            width: 100%;
            max-width: 100%;
            // padding: 0;

            gh-element{
              padding: 0;
            }
            .field-wrap-name{
              display: inline-block;
              width: 100px;
              padding-right: 14px;
              line-height: 1em;
              
              span.field-block-name{
                  min-width: 100px;
                  max-width: 100px;
                  white-space: nowrap;
                  text-align: right;
                  display: flex;
                  justify-content: flex-end;
                  position: relative;
              }

              span.field-name {
                  user-select: none;
                  padding: 0 10px 0 0;
                  font-size: 12px;
                  font-weight: 500;
                  color: $dark-grey;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  line-height: 1em;
                  display: inline-block;
                  white-space: normal;
              }
          }
        }

        gh-element[data-position*=left] {
            float: left;
            width: auto;
        }

        gh-element[data-position*=right] {
            float: right;
            width: auto;
        }

        gh-element[data-position*=beetwen] {
            width: 100% !important;
        }

        gh-element[element-type=table] {
            height: 100%;
            padding: 0;
        }
    }
}
