
/*************** FIELD SETTINGS COLUMN *****************/

.option-column_750px {
    min-width: 750px;
    max-width: 90%;
}

.option-column_500px {
    min-width: 500px;
}
/*************** FIELD SETTINGS COLUMN *****************/


gh-option-table md-autocomplete {
    min-width: initial;
}

.gh-form-wrapper .field:hover > input {
	color: #0893D2;
}

.gh-form-wrapper_editable .field > input,
.gh-form-wrapper_editable .field:focus > input,
.gh-form-wrapper_editable .field:hover > input {
    color: #A0A7AD;
    /*width: 100% !important;*/
    clear: both;
}

.gh-form-wrapper_editable .field > input:hover,
.gh-form-wrapper_editable .field > input:focus {
	border-color: #0893D2;
}

.gh-list-small {
    padding: 4px 22px 4px 5px;
    font-size: 13px;
    margin: 2px 2px;
    background-color: transparent;
    border: 1px solid #C8D1D8;
}

.gh-list-small .cross, .gh-list-large .cross{
     display: block;
    position: absolute;
    top: 50%;
    float: right;
    right: -4px;
    cursor: pointer;
}

.gh-list-small .cross{
    margin-top: -7px;
    height: 14px;
    width:14px;
}

.gh-list-large .cross{
	width: 20px;
    height: 20px;
    margin-top: -10px;
}
