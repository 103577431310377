@import "../../css/css3-mixins.scss";
@import "../../css/constants.scss";
/* ------------------------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------ GH-TIMELINE ----------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------ */

.gh-timeline {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 14px;
}
.gh-timeline .timeline__wrap {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 5px;
    max-height: 400px;
    .timeline-item {
        min-height: 95px;
        position: relative;
    }

    gh-user-selecting {
        position: absolute;
        width: 80px;
        top: 0;
        left: 0;
        font-size: 11px;
    }
}


.gh-timeline .timeline_ul {
    list-style-type: none;
    position: relative;
    border: 1px solid #e4e4e4;
    margin: 5px 0 5px 90px;
    border-radius: 5px;
    padding: 15px;
    text-align: left;
}

.gh-timeline .timeline_ul:before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background: #fff;
    top: 13px;
    left: -4px;
    position: absolute;
    transform: rotate(45deg);
    border-left: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
}

.gh-timeline .timelineDate {
    color: #b9b9b9;
    font-size: 12px;
    border-bottom: 1px solid #e4e4e4;
    display: block;
    padding-bottom: 8px;
    margin-bottom: 10px;
}

.gh-timeline .timeline_ul .timeline_li {
    position: relative;
    font-size: 14px;
    font-weight: 300;
    margin: 6px 0;

    .icon {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }
    padding-left: 20px;
}

.gh-timeline .timeline_ul li .message {
    margin-right: 5px;

    * {
        display: inline-block;
    }
}

.gh-timeline .timeline_textarea {
    width: 100%;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    resize: none;
    position: relative;
    // padding-top: 1em;
    color: #000;
}

.gh-timeline .timeline_textarea:focus {
    border: 1px solid #0893d2;
}

.gh-timeline .timeline_textarea::-moz-placeholder {
    color: #000000;
}

.gh-timeline .timeline_textarea:-moz-placeholder {
    color: #000000;
}

.gh-timeline .timeline_textarea:-ms-input-placeholder {
    color: #000000;
}

.gh-dialog-timeline-wrapper{
    overflow-y: auto;
    .timeline__wrap {
        overflow-y: initial;
        overflow-x: initial;
    }
}


.timeline_bttn {
    margin: 10px 5px 0 0;
    float: right;
}

gh-checkbox-list {
    display: block;
    padding: 5px;
    position: relative;

    md-checkbox {
        width: 100%;
    }
}
.gh-user{
  .name{
    padding-top: 14px;
  }
}
