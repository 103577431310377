@import "css3-mixins";
@import "constants.scss";

.gh-dropdown {
    position: relative;
    margin: 0 10px;
    padding: 0 0 5px;

    & > a {
        display: block;
        text-align: center;

        span {
            &.title {
                color: #a0a7ad;
                font-weight: 300;
                width: 100%;
            }
        }
    }

    & > ul {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        @include background-hex($white);
        box-shadow: 0 0 12px rgba(0,0,0,.175);
        -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
        z-index: 1000;
        padding: 5px 0;
        font-size: 14px;
        text-align: left;
        list-style: none;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        border: 1px solid rgba(0,0,0,.15);
        border-radius: 4px;

        & > li:not(.triangle) {
            min-height: 32px;
            cursor: pointer;
            white-space: nowrap;

            a {
                div {
                    color: black;
                }
            }

            div.item_name {
                line-height: 26px;
                margin-left: 32px;
            }

            &:hover {
                background-color: rgba(8, 146, 210, 0.14);
            }
        }

        & > li {
            &.triangle {
                width: 20px;
                margin: 0;
                position: absolute;
                top: -14px;
                left: 13px;
            }
        }

        ul {
            li {
                &.triangle {
                    display: none;
                    top: 0;
                }
            }
        }
    }

    & > ul.display-block {
        display: block;
        min-width: 150px;
        max-height: 150px;
        overflow-y: scroll;
    }

    .dropdown {
        margin: 0;
        padding-bottom: 0;

        ul {
            top: 0;
            left: 100%;
        }

        &:after {
            content: "";
            display: block;
            clear: both;
        }

        &:hover {
            & >  > ul {
                display: block;
            }

            ul {
                position: absolute;
                top: 0;
                left: 100%;
            }
        }
    }

    &.nav-app {
        & > a {
            span {
                &.title {
                    position: relative;
                    left: 7px;
                    width: auto;
                    font-size: 38px;
                    line-height: 53px;
                    color: black;
                    font-weight: 100;
                    margin-left: 0;
                    margin-right: 18px;
                }
            }
        }

        & > ul {
            width: 320px;
            border-radius: 10px;
            padding: 10px 2px;

            & > li:not(.triangle) {
                float: left;
                width: 25%;
                height: 77px;
                text-align: center;
                padding: 5px 10px;
                border: 1px solid transparent;

                & > a {
                    padding: 0;
                    float: left;
                    width: 100%;

                    span {
                        clear: both;
                        float: left;
                        width: 100%;
                        font-size: 10px;
                        margin-top: -5px;
                    }

                    &:focus,
                    &:hover {
                        background-color: transparent !important;
                    }
                }

                h3 {
                    color: black;
                    font-size: 10px;
                    line-height: 1;
                }

                svg {
                    width: 83%;
                    max-height: 46px;
                }

                &.home {
                    width: 64%;
                    height: 48px;
                }

                &:hover {
                    border-radius: 10px;
                    border: 1px solid #0893d2;
                    background-color: white;
                }
            }
        }
    }

    li.divider {
        height: 1px!important;
        min-height: 1px!important;
        padding: 0;
        margin: 0;
    }
}

gh-footer {
    font-size: 22px !important;
    line-height: 21px !important;
    font-weight: 100 !important;
    padding: 10px 20px !important;
}
/***create-apps***/
.create-apps {
    text-align: center;
}

.create-apps h1 {
    font-size: 60px;
    text-align: center;
    color: #0893d2;
    font-weight: 100;
    margin: 30px 0;
    line-height: 100%;
}

.create-apps h3 {
    color: #0893d2;
    font-weight: 100;
}

.create-apps .icon {
    border-radius: 15px;
    height: 68px;
}

.create-apps .app_icon,
.create-apps .app_icon svg {
    height: 270px!important;
    width: 270px!important;
    position: relative;
    margin: 0 auto;
}

.create-apps .item {
    margin: 5px 0;
}

.create-apps .item.bg-white svg {
    -webkit-box-shadow: 0 0 0 1px rgba(8,147,210,1);
    -moz-box-shadow: 0 0 0 1px rgba(8,147,210,1);
    box-shadow: 0 0 0 1px rgba(8,147,210,1);
}

.create-apps .app_icon.bg-white svg {
    border-radius: 59px;
}

.create-apps .icon.bg-white svg {
    border-radius: 15px;
}

.create-apps .item.bg-white svg {
    border-radius: 9px;
}

.wrap-textfield {
    // width: 30%;
    margin: 5px auto 36px;
    position: relative;
}

.wrap-textfield .text-icon {
    float: left;
    margin: 0;
    position: absolute;
    left: 10px;
    top: 13px;
}

.wrap-textfield input {
    float: left;
    font-size: 33px;
    line-height: 100%;
    font-weight: 100;
    border: 0 none;
    background: none;
    color: #a0a7ad;
    box-shadow: none;
    margin: 0 0 0 45px;
    border-bottom: 1px solid #0893d2;
    border-radius: 0;
    text-align: center;
}

.create-apps md-tabs-canvas {
    height: auto;
}

.create-apps md-pagination-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100%!important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.create-apps md-tabs md-pagination-wrapper .md-tab {
    text-transform: none;
    font-weight: 300;
    padding: 10px 24px 5px;
    overflow: visible;
}

.create-apps md-tabs md-tabs-content-wrapper {
    flex: 1;
    background-color: white;

    .settings {
        .setting {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 0;
            color: $dark-grey;
            span {
                margin-right: 15px;
            }
        }
    }
}

.create-apps md-tabs md-pagination-wrapper .md-tab .triangle {
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: -8px;
    left: 50%;
    margin-left: -10px;
    display: none;
}

.create-apps md-tabs md-pagination-wrapper .md-tab.md-active {
    color: #0893d2;

    .app_icon.bg-white svg,
    .icon svg,
    .icon.bg-white svg,
    .item.bg-white svg {
        -webkit-box-shadow: 0 0 0 1px rgba(8,147,210,1);
        -moz-box-shadow: 0 0 0 1px rgba(8,147,210,1);
        box-shadow: 0 0 0 1px rgba(8,147,210,1);
    }
}

.create-apps md-tabs md-pagination-wrapper .md-tab.md-active .triangle {
    display: block;
}

.create-apps md-tabs md-pagination-wrapper .md-tab .triangle svg path {
    fill: #ffffff!important;
}

.create-apps md-ink-bar {
    display: none;
}

.create-apps md-tabs md-pagination-wrapper md-tab-item .md-ripple-container {
    display: none;
}

.create-apps md-tabs md-tabs-content-wrapper md-tab-content {
    position: absolute!important;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px;
}

.create-apps md-tabs-wrapper {
    border-width: 0 0 0px!important;
}
@media screen and (max-height: 900px) {
    .create-apps h1 {
        font-size: 40px;
        margin: 15px 0;
    }

    .create-apps .app_icon,
    .create-apps .app_icon svg {
        height: 150px!important;
        width: 150px!important;
    }

    .wrap-textfield {
        margin: 5px auto 16px;
    }
}
/*end*/
.userSettings md-list md-item {
    width: 100%;
    display: block;
    border-bottom: 2px solid white;
}

.userSettings md-list md-item .button {
    border-radius: 0;
    padding: 5px 15px;
    margin: 0;
    width: 100%;
    text-align: left;
    text-transform: none;
    font-weight: 300;
    color: #A7A9B3;
    position: relative;
    height: 47px;
}

.userSettings md-item-content span:nth-child(1) {
    height: 30px;
    width: 30px;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -15px;
}

.userSettings md-item-content span:nth-child(2) {
    margin-left: 35px;
    line-height: 37px;
    font-size: 14px;
}

.userSettings md-list md-item .button svg,
.userSettings md-list md-item .button svg path {
    transition: none!important;
}

.userSettings md-list md-item .button:hover {
    color: #0893d2;
}

.userSettings md-list md-item .button:hover svg path {
    fill: #0893d2;
}

.userSettings md-list md-item .button.selected {
    background-color: #0893d2;
    color: white;
}

.userSettings md-list md-item .button.selected svg path {
    fill: #ffffff;
}

.userSettings .toolbar > button:nth-child(1) {
    visibility: hidden;
}

.userSettings .item_logo svg {
    height: 100%;
    width: 100%;
}

.userSettings .item_logo {
    height: 180px;
    width: 180px;
    margin: 15px auto;
    border: 3px solid #edf2f7;
    border-radius: 50%;
    padding: 6px;
}

.gh-ui-dialog,
gh-window.add_items {
    .add_container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .cancel-container {
            display: flex;
            justify-content: flex-end;
            padding: 10px 10px 0;
            border-radius: 10px;
        }

        .add_container_view_block {
            height: 100%;
            max-height: 100%;
            overflow-y: auto;
            display: flex;
            width: 100%;
        }

        .add_container_btn {
            max-height: 70px;
            height: 100%;
            display: flex;
            align-items: center;
            //box-shadow: 0 -2px 4px rgba(0, 0, 128, .1);
            border-top: 1px solid #dfdfdf;
            margin-top: 4px;
            padding: 5px 20px;

            button {
                margin: 0 5px;
            }

            .item_count {
                flex: 0 1 auto;
                width: 100%;
                display: flex;

                span {
                    user-select: none;
                    align-self: flex-start;
                    text-align: right;
                    padding: 0 10px 0 0;
                    font-size: 12px;
                    font-weight: 500;
                    color: #bbbcc5;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 1.4;
                    min-width: 100px;
                    max-width: 100px;
                    display: block;
                }

                input {
                    display: block;
                }
            }
        }
    }
}

.gh-ui-dialog,
gh-window.update_items {
    .update_container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .cancel-container {
            display: flex;
            justify-content: flex-end;
            padding: 10px 10px 0;
            border-radius: 10px;
        }

        .update_container_view_block {
            height: 100%;
            // max-height: 68vh;
            overflow-y: auto;
            display: flex;
            width: 100%;
        }

        .update_container_btn {
            max-height: 70px;
            height: 100%;
            display: flex;
            align-items: center;
            box-shadow: 0 -2px 4px rgba(0, 0, 128, .1);
            border-top: 1px solid #dfdfdf;
            margin-top: 4px;
            padding: 5px 20px;

            button {
                margin: 0 5px;
            }
        }
    }
}

.clone_container {
    padding: 15px;

    .cancel_container {
        display: flex;
        justify-content: flex-end;
        span{
          cursor: pointer;
        }
    }

    .clone_input_container {
        margin: 0 0 15px;
    }

    .clone_buttons_container {}
}

gh-application {
    width: 100%;

    .apps-list-icon {
        display: flex;

        &.noIcon {
            border-radius: 15px;
            border: 1px dashed #d9d9d9;
            height: 50px;
            width: 50px;
            align-items: center;
            justify-content: center;
            display: flex;
        }
    }

    .apps-default-inline {
        display: flex;
        align-items: center;

        span {
            font-weight: 100;
            margin-left: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            @media only screen and (max-width: 768px) {
                display: none;
            }
        }
    }
}
@media (max-width: 767px) {
    .userSettings .toolbar > button:nth-child(1) {
        visibility: visible;
    }
}
@media (min-width: 767px) and (max-width: 843px) {
    .navbar .navbar-nav > li > a {
        padding: 33px 6px;
    }
}
@media screen and (max-width: 767px) {
    .create-apps .btn-border {
        margin: 0 10px;
        font-size: 15px;
        line-height: 28px;
        font-weight: 100;
        /*padding: 8px 45px !important;*/
    }

    .create-apps .tab-content {
        /*height: 41%;*/
    }

    .user_options .user {
        margin-top: 0 !important;
    }

    .navbar {
        height: 70px !important;
    }

    .navbar .navbar-toggle {
        margin-top: 15px;
    }

    .navbar-user {
        margin-top: 5px !important;
    }

    .create-apps .nav-tabs {
        width: 100%;
        margin: auto;
    }

    .nav-tabs.nav-justified > li {
        display: table-cell;
        width: 1%;
    }

    .nav-tabs.nav-justified > li > a {
        margin-bottom: 0 !important;
    }

    .wrap-textfield {
        width: 90%;
    }

    .navbar-user .user_options .user-name {
        display: none !important;
    }

    .navbar-user .user_options .bot_arrow {
        margin-left: 5px;
    }

    .navbar-nav .navbar-user .gh-dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        float: left;
        width: 100%;
        padding: 5px 0;
        margin: 2px 0 0;
        font-size: 14px;
        text-align: left;
        list-style: none;
        background-color: #fff;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        border: 1px solid #ccc;
        border: 1px solid rgba(0,0,0,.15);
        border-radius: 4px;
        -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
        box-shadow: 0 6px 12px rgba(0,0,0,.175);
    }

    .dropup {
        position: inherit !important;
    }

    .gh-dropdown {
        position: relative;
    }

    .gh-dropdown-menu .triangle {
        right: 93px;
    }
}
@media screen and (max-width: 480px) {
    .navbar .navbar-toggle {
        margin-right: 10px;
    }

    .navbar .navbar-nav > li > a.btn-login {
        margin-right: 5px;
    }

    .user_options .user .name {
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}


.gh-ui-dialog {
    .dialog-delete-app{
        font-size: 16px;
        text-align: center;
        padding: 10px;
        p{
            padding-bottom: 5px;
            font-size: 20px;
            color: #0893d2;
        }
    }
    .dialog-delgete-app-content{
        text-align: center;
        padding: 10px;
        .apps-list-icon{
            width: 2em;
        }
        .app_slider_table{
            margin: 10px;
            min-height: 80vh;
            max-height: 80vh;
            overflow-y: auto;
            table{
                width: 100%;
                text-align: center;
                tr{
                    border-bottom: 1px solid #e7e7ee;
                }
                .app_slider_table_name_tr{
                    box-shadow: -1px 2px 4px rgba(0, 0, 128, 0.1);
                    td{
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 20px;
                        color: #0893d2;
                        white-space: nowrap;
                        border-bottom: 3px solid transparent;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        padding: 8px;
                    }
                }
                td{
                    width: 50%;
                    .invite_app{
                        display: inline-block;
                        width: 200px;
                        padding: 20px;
                    }
                }
            }
        }
    }
}
// ----------------Slider homepage
.container_slider {
    --n: 2;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%; // fallback
    max-height: 100vh; //  transform: translate(calc(var(--i, 0)/var(--n)*-100%));

    .slide_item {
        width: 100%; // fallback
        justify-content: space-around;
        display: flex;
        height: 70vh;
        align-items: stretch;
        flex-wrap: wrap;
        flex-direction: column;

        ul {
            margin: 0 auto;

            li {
                overflow: hidden;
            }
        }
    }
}

.slider_button {
    width: 100%;
    display: flex;
    justify-content: center;
}

.slider-button_item {
    padding: 15px;
    margin: 20px 10px 0;
    cursor: pointer;
    position: relative;
}

.slider-button_item:before {
    content: '';
    background: $white;
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin-top: -6px;
    margin-left: -6px;
}

.slider-button_active:before {
    background: $blue;
}

.container_slider {
    ul.gh_slider_wrap {
        flex-wrap: wrap;
        align-content: flex-start;

        li {
            max-height: 33%;
            min-height: 32%;

            span {
                display: inline-block;
                width: 100%;
            }
        }
            /* The container */
            .container_checkbox {
                position: absolute;
                right: 18%;
                top: 10px;
                width: 35px;
                height: 35px;
                z-index: 10;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }

            /* Hide the browser's default checkbox */
            .container_checkbox input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            }

            /* Create a custom checkbox */
            .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 35px;
                width: 35px;
                background-color: #eee;
                border-radius: 20px;
                background: rgba(0, 0, 0, 0.6);
                border: 1px solid #fff;
                border-radius: 50%;
                -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.35);
                -moz-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.35);
                box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.35);
            }

            /* On mouse-over, add a grey background color */
            .container_checkbox:hover input ~ .checkmark {
                background-color: rgba(0, 0, 0, 0.3);
                border-radius: 20px;
                cursor: pointer;
            }

            /* When the checkbox is checked, add a blue background */
            .container_checkbox input:checked ~ .checkmark {
                border-radius: 20px;
            }

            /* Create the checkmark/indicator (hidden when not checked) */
            .checkmark:after {
            content: "";
            position: absolute;
            display: none;
            border-radius: 20px;
            }

            /* Show the checkmark when checked */
            .container_checkbox input:checked ~ .checkmark:after {
            display: block;
            }

            /* Style the checkmark/indicator */
            .container_checkbox .checkmark:after {
                left: 13px;
                top: 7px;
                width: 8px;
                height: 18px;
                border: solid white;
                border-radius: 20px;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
        }
    }
}

.gh-table {
    .ref {
      width: 100%;
        .gh_text_default {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

gh-boolean{padding-top: 14px;}
.tag_text{
  padding-top: 14px;
  display: inline-block;
}

.gh-field-tex{
    padding-top: 14px;
    display: block;
    font-weight: 300;
}