@import "../../css/css3-mixins";
@import "../../css/constants.scss";

/* ------------------------------------------------------------------------------------------------------------------ */
/* ----------------------------------------------- COLOR - LIST ----------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------ */
gh-color-list {
  display: block;
  position: relative;

    ul.color-list {
        li {
            display: inline-block;
            float: left;
            margin: 2px;
            // padding: 2px;
            position: relative;
            text-align: center;

            .color {
                width: 2.5em;
                height: 2.5em;
                border: 3px solid $white;
                border-radius: 50%;
                -webkit-box-shadow: 0 0 0 1px #d9d9d9;
                -moz-box-shadow: 0 0 0 1px #d9d9d9;
                box-shadow: 0 0 0 1px #d9d9d9;
                position: relative;
            }

            .color-name {
                font-size: 14px;
            }

            .gh-color-name_block {
                max-width: 40px;
                margin-right: 5px;
                height: 17px;
                overflow: hidden;
                position: relative;
            }

            // .color-item-wrap:after {
            //     content: '...';
            //     display: block;
            //     position: absolute;
            //     bottom: 0;
            //     right: 4px;
            // }

            .check {
                display: none;
                position: absolute;
                width: 13px;
                height: 8px;
                top: 50%;
                left: 50%;
                @include transform( 'translate(-50%,-50%) rotate(-45deg)');
                border-width: 0 0 2px 2px;
                border-style: solid;
            }

            &.selected {
                .check {
                    display: block;
                }
            }
        }
    }
}
/* ------------------------------------------------------------------------------------------------------------------ */
/* ----------------------------------------------- COLOR ------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------------------------ */
.color-block-advanced{
    display: flex;
    flex-wrap: wrap;
    .color-input-container{
        width: 80%;
        min-width: 80%;
    }
    .swatch {
        font-size: 14px;
        width: 2.5em;
        height: 2.5em;
        border: 3px solid $white;
        border-radius: 50%;
        -webkit-box-shadow: 0 0 0 1px #d9d9d9;
        -moz-box-shadow: 0 0 0 1px #d9d9d9;
        box-shadow: 0 0 0 1px #d9d9d9;
        position: relative;
        margin: 10px 10px 10px 0px;
      }
}
.colorPicker{
    margin-top: 10px;
}
.color-picker-buttons {
    border-top: 1px solid $grey;
    padding: 15px 0 0px;;
    text-align: center;
    margin: 0 20px;

    button {
        display: inline-block;
        background-color: transparent;
        color: #0893d2;
        font-size: 15px;
        line-height: 6px;
        font-weight: 100;
        padding: 0 5px;
        border-radius: 15px;
        border: 1px solid transparent;
        opacity: 0.7;

        &:hover {
            opacity: 1;
        }

        svg {
            display: inline-block;
        }
        [gh-icon]{
            cursor: pointer;
            position: relative;
            margin: 0;
            right: 0;
            top: 0;
        }
    }
}

gh-color {
  display: block;
  position: relative;
    font-size: 18px;
    font-weight: 200;
    line-height: 34px;
    color: $blue;
    padding-top: 14px;

    .gh-color-item {
        height: 34px;
        width: 34px;
        border-radius: 50%;
        cursor: pointer;
    }

    [gh-icon],
    md-switch {
        cursor: pointer;
        position: absolute;
        margin: 0;
        right: 0;
        top: 50%;
        @include transform ('translate(0%,-50%)');
    }

    #color-menu {
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
        z-index: 100;
        position: fixed;
        background-color: #fff;
        width: 300px;
        border-radius: 5px;
        padding: 20px;

        & > div {
            position: relative;

            &:not(:last-child) {
                border-bottom: 1px solid #0893d2;
            }
        }

        .color-list {
            padding: 10px 5px;

            & > div {
                margin: 2px;
                float: left;
            }
        }

        .color-picker {
            margin: 0;

            color-picker {
                .color-picker-wrapper.color-picker-swatch-only {
                    width: inherit;

                    .color-picker-panel {
                        margin: 5px auto;
                    }
                }
            }
        }
    }
}
