@import "../../css/css3-mixins";
@import "../../css/constants.scss";

.gudhub{
  &.gh-ui-dialog{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 80;//I've changed index from 999 to 80 becouse it covered field settings
    overflow: hidden;

    .gh-ui-dialog-backdrop{
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      min-height: 100%;
    }

    .gh-ui-dialog-content-wrap{
      @include background-hex($white);
      @include transition('all 2.5s ease');

      .toolbar{
        @include display-flex();
        @include flex-direction();
        width: 100%;
        padding: 10px;
        // box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
        // -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);

        .toolbar-content{
          @include flex(1);

          :checked + div{ font-weight: bold; }
        }

        .toolbar-controls{
          @include flex(0);

          & > *{
            display: inline-block;
          }
        }
      }

      .content{
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 35vh;  //Is It needed ??????
      }
    }

    // ----------------------- Center position --------------------------
    &.--center{
      .gh-ui-dialog-content-wrap{
        // position: absolute;
        display: block;
        //max-height: 80%;
        max-width: 80%;
        // transform: translate(-50%,-50%);
        // top: 50%;
        // left: 50%;
        border-radius: 10px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        // overflow: hidden;

        .content{
          max-height: 100%;
        }
      }
    }
    &.--center_event_position{
      right: auto;
      bottom: auto;
      overflow: visible;
      .gh-ui-dialog-content-wrap{
        border-radius: 10px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        display: block;
        max-height: 100%;
        max-width: 100%;
        .content{
          max-height: 100%;
        }
      }
    }
    &.--full{
      .gh-ui-dialog-content-wrap{
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
      }

      .content{
        min-height: 100%; //here i chenged it to height: 100%; and Apply buton disapeared from from Pickslabs window
      }
		}
		&.--full_event_position {
			position: absolute;
			top:0px;
			left:0px;

			.gh-ui-dialog-content-wrap {
				position: absolute;
			}
		}


    // ----------------------- Bottom position -----яя---------------------
    &.--bottom{

      top: 0;
      height: 100%;
      bottom: inherit;
      animation-duration: 0.3s;
      animation-name: slideInBottomGhDialogUp;
      @keyframes slideInBottomGhDialogUp {
        from {
          top: 100%;
        }
        to {
          top: 0;
        }
      }

      &.--close{
        top: 100%;
        animation-duration: 0.6s;
        animation-name: slideInBottomGhDialogDown;
        @keyframes slideInBottomGhDialogDown {
          from {
            top: 0;
          }
          to {
            top: 100%;
          }
        }
        // Hide backdrop than we hide dialog
        .gh-ui-dialog-backdrop{
          display: none;
        }
      }

      .gh-ui-dialog-content-wrap{
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }



    &.--right{

      top: 0;
      height: 100%;
      bottom: 0;
      right: 0;
      animation-duration: 0.3s;
      animation-name: slideInRightGhDialogUp;
      @keyframes slideInRightGhDialogUp {
        from {
          right: -100%;
        }
        to {
          right: 0;
        }
      }

      &.--close{
        right: 0;
        animation-duration: 0.6s;
        animation-name: slideInRightGhDialogDown;
        @keyframes slideInRightGhDialogDown {
          from {
            right: 0;
          }
          to {
            right: -100%;
          }
        }
        // Hide backdrop than we hide dialog
        .gh-ui-dialog-backdrop{
          display: none;
        }
      }

      .gh-ui-dialog-content-wrap{
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;   
        .content{
          width: 40vw;
          max-height: 100%;
          // .gh_width_percent .gh_col_width_6 {
          //   width: 100% ;
          // }
          @for $i from 1 through 12{
            .float_block_template_repeat_form > .gh_col_width_#{$i} {
                   width: 100%;
                 }
           }
        }
      }
    }

    &.--left{

      top: 0;
      height: 100%;
      bottom: 0;
      right: 0;
      animation-duration: 0.3s;
      animation-name: slideInLefttGhDialogUp;
      @keyframes slideInLefttGhDialogUp {
        from {
          left: -100%;
        }
        to {
          left: 0;
        }
      }

      &.--close{
        top: 100%;
        animation-duration: 0.6s;
        animation-name: slideInLefttGhDialogDown;
        @keyframes slideInLefttGhDialogDown {
          from {
            left: 0;
          }
          to {
            left: -100%;
          }
        }
        // Hide backdrop than we hide dialog
        .gh-ui-dialog-backdrop{
          display: none;
        }
      }

      .gh-ui-dialog-content-wrap{
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        bottom: 0; 
        .content{
          // .gh_width_percent .gh_col_width_6 {
          //   width: 100% ;
          // }
          @for $i from 1 through 12{
            .float_block_template_repeat_form > .gh_col_width_#{$i} {
                  width: 100%;
                }
          }
          width: 40vw;
          max-height: 100%;
        }
      }
    }
    // ----------------------- Bottom absolute position -----яя---------------------
    &.--bottom_absolute{

      position: absolute;
      display: block;
      left: 0;
      right: 0;
      bottom: 0;
      top: auto;
      overflow: visible;
      animation-duration: 0.3s;
      animation-name: slideInBottomGhDialogUp;
      @keyframes slideInBottomGhDialogUp {
        from {
          top: 100%;
        }
        to {
          top: 0;
        }
      }

      &.--close{
        top: 100%;
        animation-duration: 0.6s;
        animation-name: slideInBottomGhDialogDown;
        @keyframes slideInBottomGhDialogDown {
          from {
            top: 0;
          }
          to {
            top: 100%;
          }
        }
        // Hide backdrop than we hide dialog
        .gh-ui-dialog-backdrop{
          display: none;
        }
      }

      .gh-ui-dialog-content-wrap{
        position: absolute;
        background-color: transparent;
        display: block;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
    // ---------------------- Full screen ------------------------
    &.full_screen{
    .gh-ui-dialog-content-wrap{
      top: 0;
      .content{
        max-height: calc(100% - 52px);
        // .gh_width_percent .gh_col_width_6{
        //   width: 50%;
        // }
        @for $i from 1 through 12{
          .float_block_template_repeat_form > .gh_col_width_#{$i} {
                 width: 50%;
               }
         }
      }
    }
    }
    &.--right.full_screen, &.--left.full_screen {
      .gh-ui-dialog-content-wrap{
        .content{
          width: 100%;
        }
    }
  }
  }
  
  &.gh-ui-dialog-settings{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 80;
    overflow: hidden;

    .gh-ui-dialog-backdrop{
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      min-height: 100%;
    }

    .gh-ui-dialog-content-wrap{
      background-color: #edeff0;
      @include transition('all 2.5s ease');

      .toolbar{
        @include display-flex();
        @include flex-direction();
        @include flex-align-items('center');
        @include background-hex($white);
        position: relative;
        width: 100%;
        padding: 0 10px;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
        -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
        // z-index: 1;

        .apply{
          max-width: 68px;
          min-width: 68px;
        }

        .toolbar-content{
          @include flex(1);
          @include user-select('none');


          .gh-element-type{
            float: left;
            min-width: 100px;
            max-width: 250px;
            margin: 10px 0 0 20px;
            display: flex;
            align-content: center;
            
            
            
            .gh-element-name{
              float: left;
              font-size: 17px;
              color: $dark-grey;
              margin: 7px 0 0 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .gh-element-icon{
              float: left;
            }
          }


          .gh-tabs-labels{
            @include display-flex();
            @include flex-direction();
            @include flex-justify('center');

            label{
              @include display-flex();
              @include flex-direction();
              @include flex-align-items('center');
              position: relative;
              padding: 10px;
              color: $dark-grey;
              text-transform: uppercase;

              &.active{
                color: $blue;

                &:after{
                  content: "";
                  display: block;
                  position: absolute;
                  height: 4px;
                  width: 100%;
                  bottom: 0;
                  left: 0;
                  background-color: $blue;
                }

                svg{
                  path{
                    fill: $blue!important;
                  }
                }
              }
            }
          }

        }

        .toolbar-controls{
          @include flex(0);

          & > *{
            display: inline-block;
          }
        }
      }

      .content{
        position: relative;
        // overflow-x: hidden;
        overflow-y: auto; 
        max-height: 35vh;
        min-height: 100%;
        padding-bottom: 10%;
        z-index: 0;

        .gh-tab{
          .gh-tab-content{
            display: none;
            @include flex-direction('row');
            @include flex-justify('center');
          }

          input:checked + div.gh-tab-content{
            @include display-flex();
          }
        }


      }
    }


    // ----------------------- Center position --------------------------
    &.--center{
      .gh-ui-dialog-content-wrap{
        position: absolute;
        display: block;
        max-height: 80%;
        max-width: 80%;
        transform: translate(-50%,-50%);

        top: 50%;
        left: 50%;
      }
    }


    // ----------------------- Bottom position -----яя---------------------
    &.--bottom{

      top: 0;
      height: 100%;
      bottom: inherit;
      animation-duration: 0.3s;
      animation-name: slideInBottomGhDialogUp;
      @keyframes slideInBottomGhDialogUp {
        from {
          top: 100%;
        }
        to {
          top: 0;
        }
      }

      &.--close{
        top: 100%;
        animation-duration: 0.6s;
        animation-name: slideInBottomGhDialogDown;
        @keyframes slideInBottomGhDialogDown {
          from {
            top: 0;
          }
          to {
            top: 100%;
          }
        }
        // Hide backdrop than we hide dialog
        .gh-ui-dialog-backdrop{
          display: none;
        }
      }

      .gh-ui-dialog-content-wrap{
        position: absolute;
        display: block;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
    // ----------------------- Right position -----яя---------------------
    &.--right{

      top: 0;
      height: 100%;
      bottom: 0;
      animation-duration: 0.3s;
      animation-name: slideInRightGhDialogUp;
      @keyframes slideInRightGhDialogUp {
        from {
          right: -100%;
        }
        to {
          right: 0;
        }
      }

      &.--close{
        top: 100%;
        animation-duration: 0.6s;
        animation-name: slideInRightGhDialogDown;
        @keyframes slideInRightGhDialogDown {
          from {
            right: 0;
          }
          to {
            right: -100%;
          }
        }
        // Hide backdrop than we hide dialog
        .gh-ui-dialog-backdrop{
          display: none;
        }
      }

      .gh-ui-dialog-content-wrap{
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        width: 600px;
      }
    }
    // ----------------------- Left position -----яя---------------------
    &.--left{

      top: 0;
      height: 100%;
      bottom: 0;
      animation-duration: 0.3s;
      animation-name: slideInLeftGhDialogUp;
      @keyframes slideInLeftGhDialogUp {
        from {
          left: -100%;
        }
        to {
          left: 0;
        }
      }

      &.--close{
        top: 100%;
        animation-duration: 0.6s;
        animation-name: slideInLefttGhDialogDown;
        @keyframes slideInLefttGhDialogDown {
          from {
            left: 0;
          }
          to {
            left: -100%;
          }
        }
        // Hide backdrop than we hide dialog
        .gh-ui-dialog-backdrop{
          display: none;
        }
      }

      .gh-ui-dialog-content-wrap{
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        width: 600px;
      }
    }

    // ---------------------- Full screen ------------------------
    &.full_screen{
      .gh-ui-dialog-content-wrap{
        top: 0;

        .content{
          max-height: calc(100% - 52px);
        }
      }
    }

  }
}

// Modile Version DIALOG
@media screen and (max-width: 767px) {
  .gudhub{
    &.gh-ui-dialog{
      //Full screan Dialog

      &.--left > .gh-ui-dialog-content-wrap,
      &.--right > .gh-ui-dialog-content-wrap{
        .content {
          width: 80vw;
        }

      }
      &.--left.--full_mobile{
        .resize {
          display: none;
        }
        .content {
          width: 100vw;
          height: calc(100vh - 52px);
        }
      }
      &.--full_mobile{
        .gh-ui-dialog-content-wrap{
          max-height: 100%;
          max-width: 100%;
          animation: bottom_slide_top linear 0.3s;
          position: absolute;
        }
      }
      //Bottom screan Dialog
      &.--bottom_mobile{
        height: 100%;
        width: 100%;
        top: auto;
        position: fixed;
        justify-content: normal;
        align-items: baseline;
        top: 0px !important;
        left: 0px !important;
        .gh-ui-dialog-content-wrap{
          max-height: 100%;
          max-width: 100%;
          animation: bottom_slide_top linear 0.3s;
          position: absolute;
          bottom: 0;
        }
      }
    }
  }
  
  @-webkit-keyframes bottom_slide_top {
    0%   { bottom: -100%;}
    100% { bottom: 0; }
  }
}