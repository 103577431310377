@import "css3-mixins.scss";
@import "constants.scss";

.md-virtual-repeat-offsetter {
  will-change: transform;
}
/* -------------------------------------------------------------------------------------------------------------------*/
/* -------------------------------------- EDIT TEMPLATE APPLICATION FORM ---------------------------------------------*/
/* -------------------------------------------------------------------------------------------------------------------*/

.template-container{
  border: 1px dashed $grey;
  border-radius: 25px;
  background: rgba(255, 255, 255, 1);
  padding: 25px 20px;

  .delete-field-icon,
  .edit-field-icon,
  .move-field-icon{
    width:45px;
    padding: 0 12px;
    cursor: pointer;
    opacity: 0;
  }

  .move-field-icon{
    cursor: move;
  }

  .template-container-editor{
    padding: 25px 20px;
    margin: 0 auto;
    width: 100%;

    .move-row, .trash-row{
      margin:10px;
      cursor: pointer;
    }

    .move-row{
      cursor: move;
      float: left;
    }

    .trash-row{
      float: right;
      margin-right: 0;
    }
  }

  &:hover{
    .template-container-editor{
      .move-row, .trash-row{
        path{
          fill: $blue;

        }
      }
    }
  }

  .title{
    position: relative;

    .edit-field-icon{
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      opacity: 1;
    }

    &:hover{
      .edit-field-icon{
        display: block;
      }
    }

    input{
      width: 100%;
      font-size: 28px;
      line-height: 32px;
      font-weight: 200;
      color: $blue;
      margin-bottom: 25px;
      padding: 3px 0;

      &:focus, &:active {
        & + .edit-field-icon{
          display: block;
        }
      }
    }
  }

  .fieldItemWrapper {
    min-height: 75px;

    & > div{
      @include display-flex();
      padding: 5px 0;
      background-color: white;
      border: 1px dashed transparent;
      border-radius: 15px;

      &.dndDragging{
        border-radius: 15px;
      }

      &:hover{
        border: 1px dashed #0893D2;

        .delete-field-icon,
        .edit-field-icon,
        .move-field-icon{
          opacity: 1;
        }
      }

      field-preview{
        position: relative;
        width: 100%;
        overflow: hidden;
        pointer-events: none;
      }
    }

  }

  &:hover{
    .template-container-editor{
      .move-row, .trash-row{

      }
    }
  }
}

/*
.field_options {
  position: absolute;
  z-index: 10;
  left: 50%;
  top: 50%;
  width: 300px;
  height: 100px;
  margin: -50px 0 0 -150px;
}
*/



.gh-form {
  height: 100%;
  background: rgba(255, 255, 255, 1);
  border: none;

  padding: 25px 10px;
  margin: 0 auto;
  width: 100%;
  position: absolute;
  // overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  & > .row {
    background-color: #fff;
    margin: 20px 10px;
  }

}


$col-md: (
  12: 100%,
  11: 91.6%,
  10: 83.33%,
  9: 75%,
  8: 66.67%,
  7: 58.33%,
  6: 50%,
  5: 41.67%,
  4: 33.33%,
  3: 25%,
  2: 16.67,
  1: 8.4%
);


.gh-form {
  display: block;
  flex-direction: column;

  & > .row {
    display: flex;
    flex-direction: row;
    & > .column {
      display: inline-block;;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 20px;
      // Create 'col-md-..' classes
      @each $md, $percent in $col-md {
        &.col-md-#{$md} {
          max-width: $percent;
          min-width: $percent;
        }
      }

      h2{
        min-width: 100%;
        font-size:34px;
        line-height:40px;
        white-space: nowrap; /* Запрещаем перенос строк */
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & > [gh-element]{
        flex: 0 1 auto;

        &[element-type="table"] {
          overflow: auto;
          padding-bottom: 0!important;
        }
      }
    }
  }
}

gh-window.open_app{

  .gh-form {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0;
    min-height: 100%;
    padding: 0;
    height: 100%;

    .row {
      background-color: #fff;
      margin: 10px;
      padding: 10px;
    }

    .column {
      padding: 10px;
    }

    & > .row {
      display: flex;
      flex-direction: row;
      min-height: 100%;
      padding: 0;
      margin: 0;

      & > .column {
        display: flex;
        flex-direction: column;
        padding: 0;



        & > [gh-element]{
          flex: 0;
          overflow: inherit;


          &[element-type*="table"],
          &[element-type*="tile"],
          &[element-type*="online_inventory"],
          &[element-type*="product_gallery"],
          &[element-type*="3d_edges"]{
            flex: 1;
            display: flex;
            min-width: 100%;
            max-height: none;
            padding: 0;
            overflow: auto;

            & > div{
              width: 100%;
            }

          }


        }
      }
    }
  }

}

.gh-form:last-child {}
.gh-form:not(:first-child) {
  margin-top: 30px;
}
.gh-form-wrapper_editable {
  background-color: rgba(237, 239, 240, 1) !important;
}

.gh-form-wrapper__form-caption {
  font-size: 28px;
  line-height: 40px;
  font-weight: 200;
  color: #0893d2;
  margin-bottom: 25px;
  border-bottom: 1px solid #0893d2;
  max-height: 41px;
}
.gh-form-wrapper__table-settings-caption {
  font-size: 48px;
  line-height: 50px;
  font-weight: 200;
  color: #0893d2;
  margin-bottom: 25px;
}
.cross-icon {
  background: none;
  border: 0 none;
  border-radius: 0;
}
.cross-icon:hover {
  opacity: 0.8;
}



/* -------------------------------------------------------------------------------------------------------------------*/
/* --------------------------------------------------- EDIT FIELD ----------------------------------------------------*/
/* -------------------------------------------------------------------------------------------------------------------*/

// ------------------------------------------  Field preview in top of form --------------------------------------------


.field-constructor-preview{

  border-radius: 20px;
  width:100%;
  min-height: 115px;
  max-height: 300px;
  margin:0 auto;
  padding: 7px 0;
  @include box-shadow(0px, 0px, 20px, rgba(185, 185, 185, 0.1));
  @include background-hex($white);
  @include display-flex();
  flex-wrap: nowrap;

  & > *{
    @include flex(1);

    &:nth-child(1){
      min-width: 150px;
      max-width: 150px;
      border-right: 1px solid $light-grey;
      @include display-flex();
      @include flex-direction('column');
      @include flex-justify('center');
    }

  }

  field-preview{
    overflow: auto;
    margin: 15px;
    position: relative;

    & > *{
      pointer-events: none;
    }

    gh-table{
      min-height: 170px
    }

    .gh-date-input, gh-duration{
      width:auto;
    }

  }

  .preview-icon{
  	padding: 0 15px;
  	max-width: 150px;
  	text-align: center;
  }

  .field-name{
    font-size: 18px;
    font-weight: 300;
    color: $dark-grey;
    text-align: center;
  }
}


// ---------------------------------------------  Field field settings tabs --------------------------------------------
field-settings {

  @include display-flex();
  @include flex-direction('column');


  .md-tab {
    text-transform: none;
    padding: 5px 5px 15px;
    line-height: 15px;
    color: #C0C1C3;
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 15px;
    overflow: visible;

    .md-ripple-container {
      display: none;
    }

    &.md-active {
      color: #0893d2;

      .triangle {
        display: block;
      }

      .icon {
        svg {
          border: 1px solid #0893d2;

          path {
            fill: #0893d2;

            background-color: #F6F7F7;
          }
        }
      }
    }

    .icon {
      svg {
        border: 1px solid #C0C1C3;
        border-radius: 15px;

      }
    }

    .triangle {
      display: none;
      height: 21px;
      position: absolute;
      bottom: -2px;
      left: 50%;
      margin-left: -15px;
    }
  }

  md-tabs {

    flex: 1;
    @include display-flex();
    @include flex-direction('column');
    @include flex(1);


    &.md-dynamic-height {
      md-tabs-content-wrapper {
        height: 100%;
        background: #f6f7f7;
        @include flex(1);
        position: relative;
        top: 0;
        overflow-y: auto;
        overflow-x: hidden;

        md-tab-content {
          overflow-x: hidden;
          overflow-y: visible;
          height: 100%;

          div[md-tabs-template]{
            @include display-flex();
            justify-content:center;
            flex-flow: row wrap;
            align-items: flex-start;
          }

        }
      }
    }

    &[md-border-bottom] {
      md-tabs-wrapper {
        border: none;
      }
    }

  }

  md-tabs-canvas {
    height: auto;
  }

  h3 {
    font-size: 28px;
    line-height: 40px;
    font-weight: 200;
    color: $blue;
    margin: 15px 0;
    border-bottom: 1px solid $blue;
  }

  md-pagination-wrapper {
    height: 100%;
    width: 100% !important;
    position: relative;
    @include display-flex();
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  md-ink-bar {
    display: none;
  }

  // ----------------------------- Column option in tab ---------------------------
  .option_column {
    max-width: 400px;
    display: block;
    width: 90%;
    height: auto;
    background-color: #fff;
    border-radius: 20px;
    padding: 15px 25px;
    border: 1px solid #d8d8d8;
    margin: 10px;
    box-shadow: 0px 0px 20px 0px rgba(185, 185, 185, 0.10);

    // ---------------------- Field setting row ------------------------
    field-setting {
      & > * {
        width: 100%;

        gh-code-generator {
          width: 100%;
        }

        & > [gh-element] {
          width: 100%;
          font-size: 14px;

          & > gh-radio-button {
            font-size: 13px;

              & > gh-radio-button{
                font-size: 13px;

                & > ul{
                  @include display-flex();
                  @include flex-justify('center');

                  & > li{
                    padding: 10px;
                    // max-width: 25%;
                  }
                }
              }

              & > li {
                padding: 10px;
                // max-width: 25%;
              }
            }
          }

          md-switch {
            margin-top: 9px;
            margin-bottom: 9px;
            float: right;
          }

        }

        & > gh-option-table, & > gh-view-element-setting {
          width: 100%;
        }

      }

      // ---------------------------- Font style settings ------------------------------------
      .font-size-setting{
        @include display-flex();
        @include flex-direction('row');

        min-width: 100%;

        & > * {
          padding: 5px 8px;
          @include flex-align-self('end');


          p {
            color: $blue;
          }

          &:nth-child(2){
            @include flex(1);
          }

          &:nth-child(4) {
            input {
              border-bottom: 2px solid $blue;
              font-size: 18px;
              text-align: center;
              max-width: 109px;
            }
          }
        }
      }

    }

    // ---------------------- Select table of interpretation  ------------------------
    &.interpretation-type {
      max-width: 500px;

      md-radio-group {

        $button_width: 20px;
        $button_container_width: 48px;

        & > md-radio-button{
          @include display-flex();

          margin: 0;

          &:not(:last-child) {
            border-bottom: 1px solid $light-grey;
          }

          & > .md-container {
            width: $button_width;
            height: $button_width;
            right: $button_container_width/2 - $button_width/2;
            left: inherit;
          }

          & > .md-label {
            margin: 0;
            float: right;
            max-width: 400px;
            min-width: 400px;
            height: 100%;
            padding: 10px;
            margin-right: $button_container_width;
            border-right: 1px solid $light-grey;

            field-preview {
              display: block;
              position: relative;
              overflow: hidden;
            }
          }

        }
      }
    }

    // ---------------------- Table of interpretation types ------------------------
    &.interpretation_type_settings_table {
      max-width: 700px;
      padding: 0;

      table {
        width: 100%;
        border-radius: 20px;
        overflow: hidden;

        th {
          font-size: 26px;
          text-align: center;
          color: $blue;
          padding: 10px;
        }

        tr {

          &:not(:last-child) {
            border-bottom: 1px solid $light-grey;

            td:nth-child(1) {
              pointer-events: none;
            }
          }

          &:nth-child(even) {
            @include background-hex($light-grey, 0.3);
          }

          // ------------ Adding interpretation button -----------
          &:last-child {
            font-size: 26px;
            color: $white;
            text-align: center;
            cursor: pointer;
            @include background-hex($green);

            td {
              padding: 0;
              position: relative;

              & > div{
                @include display-flex();
                @include flex-direction('row');
                @include flex-justify('center');

                padding: 10px;

                & > span {
                  display: inline-block;

                  &:nth-child(1) {
                    width: 25px;
                    height: 25px;
                  }

                  &:nth-child(2) {
                    margin-left: 5px;
                  }
                }
              }

              & > div[gh-element] {
                position: absolute;
                display: block;
                height: 100%;
                width: 100%;

                & > * {
                  display: none;
                }
              }
            }

            &:hover {
              @include background-hex($white);
              color: $green;

              td {
                span[gh-icon] {
                  svg {
                    path {
                      fill: $green !important;
                    }
                  }
                }
              }

            }
          }

          td {
            &:not(:last-child) {
              border-right: 1px solid $light-grey;
            }

            padding: 8px;
            vertical-align: middle;
            max-width: 320px;
            overflow: hidden;

            &:nth-child(2) {
              width: 200px;
              font-size: 18px;
              text-align: center;
            }
          }
        }
      }
    }
  }

/* ----------------------------------------------------------------------------*/
/* ----------------------------- END DND LIBRARY ------------------------------*/
/* ----------------------------------------------------------------------------*/
.user-settings-form {
  width: 62%;
  margin: 0 auto;
  position: relative;
  margin-top: 30px;
  .simple-form {
    margin-top: 30px;
  }
  div[gh-icon] {
    position: absolute;
  }
  gh-input {
    padding-left: 60px;
    & > span,
    & > span > input {
      width: 100%;
    }
  }
  .relative {
    box-sizing: border-box;
    overflow: hidden;
  }
  .custom-button {
    margin: 20px auto;
    padding: 10px 40px;
    border-radius: 30px;
    font-family: 'Roboto';
    font-weight: 100;
    font-size: 18px;
    background-color: #0893d2;
    border: 1px solid transparent;
    color: #fff;
  }
}







/*** Close Button ***/
.cross-icon{
  background: none;
  border: 0 none;
  border-radius: 0;

}
.cross-icon:hover {
  opacity: 0.8;
}

/* dnd library */

div[dnd-list], div[dnd-list] > div {
  position: relative;
}

.dndPlaceholder {
  background: rgba(8, 146, 210, 0.14);
  display: block;
  position: relative;

}


.actions-toolbar div[dnd-list] > div {
  float: left;
  display: block;
  width: 120px;
  height: 75px;
  text-align: center;
}


div[dnd-allowed-types*='field'] > .dndPlaceholder {
  height: 35px;
  border-radius: 17.5px;
  margin: 6px 0;
  /*padding-top: 10px;*/
}

div.navbars-dnd > .dndPlaceholder {
  height: 8px;
  /*border-radius: 17.5px;*/
  margin: 0 20px;
  float: left;
  display: block;;
  width: 10px;
  height: 90px;
  /*padding-top: 10px;*/
}

div[dnd-allowed-types*='row'] > .dndPlaceholder {
  height: 50px;
  border-radius: 25px;
  margin-top: 20px;
}

.actions-toolbar span > .dndPlaceholder {
  /*display: none;*/
  width:100%;
  height: 15px;
  /*display:block;*/
  /*position: relative;*/
  /*float: left;*/
}

.actions-toolbar div[dnd-allowed-types*='field'] .dndDraggingSource {
  display: none;
  /*opacity: 0.6;*/
  background-color: #0893D2;
}

.dndDragging {
  background-color: rgb(220, 239, 248);
  -webkit-transition: max-height linear 0.4s;
  -moz-transition: max-height linear 0.4s;
  -ms-transition: max-height linear 0.4s;
  -o-transition: max-height linear 0.4s;
  transition: max-height linear 0.8s;
}

/*
.animate {
	-webkit-transition: max-height linear 0.5s;
	-moz-transition: max-height linear 0.5s;
	-ms-transition: max-height linear 0.5s;
	-o-transition: max-height linear 0.5s;
	transition: max-height linear 0.5s;
}
*/

.dndrowdrag__begin-animation {
  /*max-height: 1000px;*/
}

.dndrowdrag {
  max-height: 150px;
  overflow: hidden;
}

.draggable-container {
  background-color: rgba(237, 239, 240, 1) !important;
  /*padding: 0 25px 25px 25px;*/


  padding: 30px 10px;
}





/*


------------------------------------------------------------------------------------------------------------------------



*/



.gh-view{
  position: relative;
  @include display-flex();
  @include flex-direction('column');
  @include flex(1);
  background-color: rgba(237, 239, 240, 1);
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;

  & > .gh-template-container{
    @include flex(1);
  }



  .gh-template-container{
    @include display-flex();
    @include flex-direction('column');
    @include flex-justify('start');
    min-height: 100%;
    max-height: 100%;
    padding: 2px 4px;



    .gh-template{
      @include background-hex($white);
      position: relative;
      background: rgba(255, 255, 255, 1);
      border-radius: 10px;
      border: 1px dashed $white;
      z-index: 0;
      margin: 2px 0;
      width: 100%;

      //&:hover{
      border-color: $blue;
      //}


    }

    .gh-template.gh-ui-panel{
      @include flex(0);
      min-height: 40px;
      @include display-flex();
      @include flex-direction('column');
      @include flex(0, 1, 100%);

      & > .gh-template-wrap{
        @include display-flex();
        @include flex-direction('column');
        @include flex(1);

        & > .gh-template-container{
          @include display-flex();
          @include flex-direction();
          @include flex-justify('start');
          @include flex(1);
          flex-wrap: wrap;

        }
      }
    }

    .gh-template.gh-ui-row{
      min-height: 40px;
      @include display-flex();
      @include flex-direction('column');
      @include flex(0, 1, 100%);

      & > .gh-template-wrap{
        @include display-flex();
        @include flex-direction('column');
        @include flex(1);

        & > .gh-template-container{
          @include display-flex();
          @include flex-direction();
          @include flex-justify('start');
          @include flex(1);
          flex-wrap: wrap;

        }
      }
    }

    .gh-template.gh-ui-content_wrap{
      @include flex(1);
      position: relative;

      & > .gh-template-wrap{
        display: block;
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
      }
    }

    .gh-template.gh-ui-container{
      @include flex(1, 1, 100%);

      & > .gh-template-wrap{
        @include display-flex();
        @include flex-direction('column');
        @include flex(1);

        & > .gh-template-container{
          @include display-flex();
          @include flex-direction();
          @include flex-justify('flex-start');
          @include flex(1);
          flex-wrap: nowrap;
        }
      }
    }

    .gh-template.gh-ui-column{
      @include flex(1);

      & > .gh-template-wrap{
        & > .gh-template-container{
          min-height: 40px;
        }
      }
    }
  }
}





field-preview{
  & > *{
    pointer-events: none;
  }
}



.gh-template-editor{

  @include display-flex();
  @include flex-direction();
  flex-wrap: nowrap;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;

  ul, li{
    min-height: 40px;
  }

  .gh-templates-list{
    position: relative;
    min-width: 300px;
    max-width: 300px;
    z-index: 0;

    .gh-tab{
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0 10px;

      .gh-tab-content{
        padding: 5px;
        display: none;

        .gh-search-block{
          display: flex;
          padding: 0;
          align-items: flex-end;

          input{
            margin-left: 5px;
          }
        }

        &.activeTab{
          display: block;
        }
      }

      .container-constructor, .field-constructor{
        padding: 15px;
        margin: 12px 0;
        border-radius: 8px;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
        -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
      }
    }
  }

  .gh-view{


    .dndPlaceholder {
      @include background-hex($blue, 0.14);
      display: none;
      width: 100%;
      min-height: 10px;
      border-radius: 8px;
      border: 1px dashed $blue;
    }

    .dndDraggingSource {
      display: none!important;
    }

    .dndDragover{
      .dndPlaceholder{
        display: block;
      }
    }
  }

}


































.gh-template-container-old{
  position: relative;
  min-height: 40px;
  z-index: 0;

  .gh-template-title{
    @include display-flex();
    @include flex-direction();
    flex-wrap: nowrap;
    position: relative;
    z-index: 2;

    .data{
      font-size: 24px;
      font-weight: 200;
      color: $dark-grey;
      @include flex(1);
      @include display-flex();
      @include flex-direction();
      @include flex-align-self('center');
      flex-wrap: nowrap;
      overflow: hidden;

      .name{
        @include flex(1);

        input{
          border: none;
          width: 100%;
          text-overflow: ellipsis;
        }
      }
    }


    .edit-controls{
      display: none;
      @include flex-direction();
      @include flex-align-self('center');

      .dropdown{
        margin: 0 2px;

        label{
          padding-bottom: 3px;
        }

        ul{
          position: absolute;
          top: 100%;
          left: inherit;
          right: 0;
          z-index: 1;
          display: none;
          @include background-hex($white);
          padding: 5px 0;
          border: 1px solid $grey;
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
          -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

          li{
            @include display-flex();
            @include flex-direction();

            flex-wrap: nowrap;
            position: relative;
            padding: 5px 10px;
            color: rgba(0,0,0,0.6);
            cursor: pointer;

            & > *{
              @include flex-align-self('center');
            }

            p{
              @include flex(1);
              margin-left: 10px;
              min-width: 90px;
            }
          }

          ul{
            top: 0;
            left: 100%;
          }
        }

        &:hover{
          & > ul{
            display: block;
          }
        }
      }
    }
  }

  .gh-template-list{
    position: relative;
    z-index: 1;


  }

  .gh-template-wrap{



    &:after{
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }

    // Create 'gh-coll-..' classes
    @for $i from 1 through 100 {
      &.gh-coll-#{$i} {
        flex: $i;

        & + .dndPlaceholder {
          display: inline-block;
          width: 10px;
          min-height: 80px;
        }
      }
    }

    .gh-template{
      & > div{
        position: relative;


        field-preview{
          display: block;
          width: 100%;
          overflow: hidden;
        }
      }



      // ---------------- Element style --------------------
      .element{
        @include display-flex();
        @include flex-direction();
        flex-wrap: nowrap;

        field-preview{
          @include flex(1);
        }

        // Edit button when we not hover it
        .edit{
          display: none;
        }
      }
      // -------------- end Element style ------------------
    }


    // ---------------- Container --------------------
    &.gh-ui-container{
      //display: inline-block;

      & > .gh-template{
        width: 100%;

        & > .gh-template-list{
          & > ul{
            @include display-flex();
            @include flex-direction();
          }
        }
      }
    }



    // ----------------------------------------------- Tabs ------------------------------------------------------------
    &.gh-ui-tabs{
      width: 100%;
      @include display-flex();
      @include  flex-direction('column');

      .gh-tabs-labels{

        @include display-flex();
        @include flex-direction();

        & > *{
          border: 1px dashed $white;

          max-width: 150px;

          &.dndPlaceholder{
            max-width: 10px;
          }

          &:hover{
            border-color: $blue;
            .edit-controls{
              @include display-flex();
            }
          }

          .gh-template-title{
            .data{
              input{
                text-transform: uppercase;
              }
            }
          }

          &.active{
            .gh-template-title{
              .data{
                color: $blue;

                &:after{
                  content: '';
                  display: block;
                  position: absolute;
                  width: 100%;
                  height: 3px;
                  @include background-hex($blue);
                  bottom: 0;
                }
              }
            }
          }
        }
      }

      .gh-tabs-wrap{
        .gh-ui-tab{
          display: none;

          &.active{
            display: block;
          }
        }
      }
    }
    // --------------------------------------------- End Tabs ----------------------------------------------------------




    // --------------------------------------------- Dropdown ----------------------------------------------------------
    &.gh-ui-dropdown{
      display: inline-block;

      &:hover,
      &.gh-mouse-dnd{
        z-index: 10;

        & > .gh-template{
          & > .gh-template-list{
            //& > .gh-template-container{
              display: block;
            //}
          }
        }
      }



      & > .gh-template{
        & > .gh-template-list{
         // & > .gh-template-container{
            display: none;
            position: absolute;
            min-width: 150px;
            min-height: 40px;
            top: 100%;
            left: 0;
            @include background-hex($white);
            padding: 5px 0;
            border: 1px solid $grey;
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
            -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
         // }
        }
      }

      .gh-ui-dropdown{
        & > .gh-template{
          & > .gh-template-list{
            //& > .gh-template-container{
              top: 0;
              left: 100%;
            //}
          }
        }
      }

    }
    // ------------------------------------------ End Dropdown ---------------------------------------------------------
  }

}









.option_column{
  max-width: 400px;
  width: 90%;
  height: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 15px 25px;
  border: 1px solid #d8d8d8;
  margin: 10px;
  box-shadow: 0px 0px 20px 0px rgba(185,185,185,0.10);


  // ---------------------- Field setting row ------------------------
  field-setting{
    h3{
      font-size: 28px;
      line-height: 40px;
      font-weight: 200;
      color: #0893d2;
      margin: 15px 0;
      border-bottom: 1px solid #0893d2;
    }
    
    gh-boolean {
      padding-top: 0;
    }

    gh-radio-button {
      padding-top: 0;
    }
    
    & > *{
      width: 100%;

      gh-code-generator{
        width: 100%;
      }

      & > [gh-element]{
        width: 100%;
        font-size: 14px;


        & > gh-radio-button{
          font-size: 13px;

          & > ul{
            @include display-flex();
            @include flex-justify('center');

            & > li{
              padding: 10px;
              // max-width: 25%;
            }
          }
        }

        md-switch{
          margin-top: 9px;
          margin-bottom: 9px;
          float: right;
        }


      }

      & > gh-option-table, & > gh-view-element-setting{
        width: 100%;
      }

    }

    // ---------------------------- Font style settings ------------------------------------
    .font-size-setting{
      @include display-flex();
      @include flex-direction();
      min-width: 100%;

      & > *{
        padding: 5px 8px;
        @include flex-align-self('end');

        p{
          color: $blue;
        }

        &:nth-child(2){
          @include flex(1);
        }

        &:nth-child(4){
          input{
            border-bottom: 2px solid $blue;
            font-size: 18px;
            text-align: center;
            max-width: 109px;
          }
        }
      }
    }



  }

  // ---------------------- Select table of interpretation  ------------------------
  &.interpretation-type{
    max-width: 500px;

    md-radio-group{

      $button_width: 20px;
      $button_container_width: 48px;

      & > md-radio-button{
        @include display-flex();
        margin: 0;

        &:not(:last-child){
          border-bottom: 1px solid $light-grey;
        }

        & > .md-container{
          width: $button_width;
          height: $button_width;
          right: $button_container_width/2 - $button_width/2;
          left: inherit;
        }

        & > .md-label{
          margin: 0;
          float: right;
          max-width: 400px;
          min-width: 400px;
          height: 100%;
          padding: 10px;
          margin-right: $button_container_width;
          border-right: 1px solid $light-grey;

          field-preview{
            display: block;
            position: relative;
            overflow: hidden;
          }
        }

      }
    }
  }

  // ---------------------- Table of interpretation types ------------------------
  &.interpretation_type_settings_table{
    max-width: 700px;
    padding: 0;

    table{
      width: 100%;
      border-radius: 20px;
      overflow: hidden;

      th{
        font-size: 26px;
        text-align: center;
        color: $blue;
        padding: 10px;
      }

      tr{

        &:not(:last-child){
          border-bottom: 1px solid $light-grey;

          td:nth-child(1){
            pointer-events: none;
          }
        }

        &:nth-child(even){
          @include background-hex($light-grey, 0.3);
        }

        // ------------ Adding interpretation button -----------
        &:last-child{
          font-size: 26px;
          color: $white;
          text-align: center;
          cursor: pointer;
          @include background-hex($green);

          td{
            padding: 0;
            position: relative;

            & > div{
              @include display-flex();
              @include flex-direction();
              @include flex-justify('center');
              padding: 10px;

              & > span{
                display: inline-block;

                &:nth-child(1){
                  width: 25px;
                  height: 25px;
                }

                &:nth-child(2){
                  margin-left: 5px;
                }
              }
            }

            & > div[gh-element]{
              position: absolute;
              // @include display-block();
              height: 100%;
              width: 100%;

              & > *{
                display: none;
              }
            }
          }

          &:hover{
            @include background-hex($white);
            color: $green;

            td{
              span[gh-icon]{
                svg{
                  path{
                    fill: $green!important;
                  }
                }
              }
            }

          }
        }

        td{
          &:not(:last-child){
            border-right: 1px solid $light-grey;
          }

          padding: 8px;
          vertical-align: middle;
          max-width: 320px;
          overflow: hidden;

          &:nth-child(2){
            width: 200px;
            font-size: 18px;
            text-align: center;
          }

          &:nth-child(3),
          &:nth-child(4){
            width: 60px;
          }

          &:nth-child(3){
            border-right: 0;
          }

          &:nth-child(4){
            padding: 10px;
          }

          .element-wrap-container{
            display: flex;


          }
        }

      }
    }
  }

  // ----------------------------- Calculation column -----------------------------------
  .calculatorData{
    max-width: 50%;
    padding: 0;
  }

}
























.gh-tabs {
  @include display-flex();
  @include flex-direction('column');
  max-height: 100%;
  position: relative;
  margin: 0;
  text-align: left;
  z-index: 1;

  .gh-tabs-labels {
    white-space: nowrap;

    label {
      display: inline-block;
      font-weight: normal;
      padding: 2px 5px;
      cursor: pointer;
      position: relative;
      font-size: 12px;

      &:hover {

      }
    }
  }

  .gh-tab {
    & > div {
      .gh-tab-content {
        z-index: 2;
        display: none;
        // overflow: hidden; removed for correctly view filter
        width: 100%;
        font-size: 17px;
        padding: 5px;
        top: 53px;
        left: 0;
      }

      input[type="radio"] {
        position: absolute;
        top: 0;
        left: -9999px;
      }

      & > input:checked ~ [class^="gh-tab-content"] {
        display: block;
      }
    }
  }
}

.gh-form_element:first-child{
  float: left;
}

/*----------------------------------*/
.option-column_full_size{
  max-width: none;
  padding: 0;
  margin: 0;
  width: 100%;
  border: none;
  box-shadow: none;
  border-radius: 0;
}

.option-column_800_size{
  max-width: 800px;
}

.CodeMirror{
  min-height: 300px;
  height: 100% !important;
}

.CodeMirror-scroll{
  min-height: 300px;
  width: 100%;
}
