/* -------------------------------------------------------------------------------------------------------------------*/
/* ------------------------------------------------ CONSTANTS --------------------------------------------------------*/
/* -------------------------------------------------------------------------------------------------------------------*/
$blue: #0893d2;
$light-blue: #EDF2F6;  // Changed color,watch if somewhere will be bugs
$sky-blue: #ddf0f9;
$green: #10cfbd;
$red: #FF3333;
$pure-white: #fff;
$white: #fafdfd;
$black: #575757;
$light-grey: #e7e7ee;
$grey: #d9d9d9;
$dark-grey: #bbbcc5;
$yellow: #ffea00;