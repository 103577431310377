@import "../css/css3-mixins";
@import "../css/constants.scss";

/*-------Login--------*/
// .field-name,.field.email,.field.login,.field-name,.gh-field_input,.gh-auth-form div.field div.field-name{
//     display: inline-block;
// }
/*---------------------------------------*/
/************ gh-auth-form *************/
/*---------------------------------------*/
.gh-auth-form {
    transition: 0.1s ease-out all;
}

.gh-auth-form .closeBtn,
.gh-auth-form .closeBtn svg {
    width: 48px;
    height: 48px;
}

.gh-auth-form .closeBtn {
    margin: 20px 10px;
}

.gh-auth-form,
.gh-auth-form form {
    position: relative;
}

.gh-auth-form form {
    margin-top: 20px;
    min-width: 349px;
}

.gh-field_input {
    margin-left: 5px;
}
.gh-auth-form div.field input{
  padding-top: 0;
}
.gh-auth-form .title {
    font-size: 58px;
    line-height: 40px;
    font-weight: 200;
    color: #0893d2;
    margin: 30px 0;
}

.gh-auth-form .gudhub-logo,
.gh-auth-form .gudhub-logo svg,
.gh-auth-form gh-avatar,
.gh-auth-form gh-avatar > div,
.gh-auth-form gh-avatar > div svg,
.gh-auth-form gh-avatar div.cloud-keyframe,
.gh-auth-form gh-avatar svg {
    width: 180px;
    height: 180px;
}

.gh-auth-form gh-avatar > div {
    margin: 15px auto;
}

.gh-auth-form gh-avatar div.cloud-keyframe svg {
    width: 65%;
    height: auto;
}

.gh-auth-form div[layout=row] {
    position: relative;
}

.gh-auth-form div.help-block {
    margin-top: 0;
    margin-bottom: 0;
}

.gh-auth-form div.cheked {
    position: absolute;
    right: 0;
    top: 8%;
}

.gh-auth-form div.help-block p {
    color: #FF5B5B;
    font-size: 12px;
    line-height: 1.3;
    padding: 0;
    margin: 0;
}

.gh-auth-form div.field {
    height: 61px;
}

.gh-auth-form div.field {
    transition: 0.3s linear all;
    height: 61px;
    overflow: hidden;
    position: relative;
}

.gh-auth-form div.field.ng-hide {
    height: 0;
}

.gh-auth-form div.field div.field-name {
    padding-top: 14px;
    display: inline-block;
    float: left;
    text-align: right;
    background: none;
    border: 0 none;
    border-radius: 0;
    box-shadow: none !important;
    font-size: 12px;
    font-family: roboto;
    font-weight: 500;
    color: #A7A9B3;
    // border-bottom: 1px solid #fff;
    overflow: hidden;
    text-overflow: ellipsis;
}

.gh-auth-form div.field.error div.field-name {
    color: #FF5B5B!important;
}

.gh-auth-form div.field.focus div.field-name,
.gh-auth-form div.field:hover div.field-name {
    color: #0893D2;
}

.gh-auth-form div.field input {
    padding: 8px 0 5px 5px;
    display: block;
    background: none;
    font-size: 18px;
    color: #626262;
    font-weight: 300;
    border-width: 0;
    border-radius: 0;
    border-bottom: 1px solid #edf2f7;
    box-shadow: none;
}

.gh-auth-form div.field input:active,
.gh-auth-form div.field input:focus,
.gh-auth-form div.field.focus input,
.gh-auth-form div.field:hover input {
    border-bottom: 1px solid #0893D2;
}

.gh-auth-form div.field.error input,
.gh-auth-form div.field.error input:active,
.gh-auth-form div.field.error input:focus {
    border-bottom: 1px solid #FF5B5B!important;
}

.gh-auth-form .btn {
    margin: 20px;
}

.gh-auth-form input,
.gh-auth-form select,
.gh-auth-form textarea {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    -moz-box-shadow: 0 0 0 1000px white inset !important;
    box-shadow: 0 0 0 1000px white inset !important;
}

@media screen and (max-width: 400px){
    .gh-auth-form{
        .closeBtn{
            width: 39px;
            height: 35px;
            margin: 10px;
        }
        .title{
            font-size: 42px;
            line-height: 30px;
            margin: 10px 0;
        }
        .gudhub-logo{
            width: 70px;
            height: 70px;

            & > div{
                width: 70px;
                height: 70px;
                svg{
                    width: 100%;
                    height: 100%;
                }
            }
            svg{
                width: 100%;
                height: 100%;
            }
        }
        gh-avatar{
            width: 70px;
            height: 70px;
            
            & > div{
                width: 70px;
                height: 70px;
                svg{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        
    } 
}