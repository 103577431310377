@import "../../css/constants.scss";
json-editor {
  width: 100%;
  height: 100%;
  position: relative;
  .jsoneditor {
    height: 100vh;
    border: thin solid $blue;
  }
  a.jsoneditor-poweredBy {
    display: none;
  }
  .json_buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
    position: absolute;
    right: 0;
    top: 4px;
    .copy_btn, .save_btn, .paste_btn {
      cursor: pointer;
      margin-right: 20px;
    }
    .copy_btn, .paste_btn {
      svg {
        path {
          stroke-width: 11px;
          stroke: #fff;
        }
      }
    }
  }
  .paste_container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(0,0,0,.6);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .json_editor_help {
      font-size: 35px;
      color: $white;
      margin-bottom: 30px;
      text-align: center;
    }
    textarea {
      width: 80%;
      height: 40%;
      background-color: $white;
      resize: none;
    }
    .apply_btn {
      margin-top: 30px;
    }
    .json_error {
      font-size: 14px;
      color: $red;
      margin-top: 20px;
    }
  }
  .json_waiting {
    width: 100%;
    height: 96.3%;
    background-color: rgba(0,0,0,.6);
    position: absolute;
    top: 37px;
    left: 0;
  }
  .jsoneditor-menu {
    background-color: $blue;
    border-bottom: 1px solid $blue;
    button {
      opacity: 1;
    }
  }
}