@import "../../css/constants.scss";
body {
  &.gudhub {
    .home_container {
      max-width: 1580px;
      margin: 0 auto;
      padding: 0 40px;
      @media (min-width: 1024px) and (max-width: 1600px) {
        max-width: 1024px;
      }
      @media all and (max-width: 1024px) {
        width: 100%;
        flex-wrap: wrap;
      }
      &-small {
        max-width: 1024px;
        padding: 0;
        @media all and (max-width: 1023px) {
          justify-content: center;
        }
      }
    }
    .gh-home-page {
      .home_navbar {
        position: absolute;
        display: flex;
        align-items: center;
        .gh-home-link {
          a {
            margin: 5px 0!important;
            padding: 3px 15px!important;
          }
        }
        @media all and (max-width: 679px) {
          .app_icon {
            margin-left: 10px;
            padding: 0;
          }
        }
      }
      .half_width {
        width: 50%;
        @media all and (max-width: 1023px) {
          width: 100%;
          &.wally_wrapper {
            display: flex;
            justify-content: center;
          }
          .gwd-page-wrapper {
            position: relative;
          }
        }
      }
      .w-60 {
        width: 60%;
      }
      .w-40 {
        width: 40%;
      }
      .default_title {
          color: $blue;
          font-size: 44px;
          line-height: 1.7;
          font-weight: 400;
          margin-bottom: 25px;
          @media (min-width: 1024px) and (max-width: 1600px) {
           font-size: 33px;
          }
          @media all and (max-width: 1023px) {
            font-size: 23px;
            margin-bottom: 15px;
            line-height: 1.3;
          }
      }
      .default_subtitle {
          font-size: 20px;
          color: $black;
          font-weight: 300;
          line-height: 1.7;
          @media (min-width: 1024px) and (max-width: 1600px) {
            font-size: 17px;
          }
          @media all and (max-width: 1023px) {
            font-size: 14px;
          }
      }
      .home_top {
        background-color: $light-blue;
        padding: 187px 0 130px 0;
        .home_container {
          @media all and (max-width: 1023px) {
            flex-direction: column-reverse;
          }  
        }
        @media (min-width: 1200px) and (max-width: 1600px) {
          padding: 130px 0 80px 0;
          .gwd-page-content {
            transform: scale(.7);
          }
          .gwd-lightbox {
            overflow: inherit;
          }
          .gwd-page-size {
            width: 70%;
            height: auto;
          }
        }
        @media (min-width: 1024px) and (max-width: 1199px) {
          padding: 130px 0 80px 0;
          .gwd-page-content {
            transform: scale(.6);
          }
          .gwd-lightbox {
            overflow: inherit;
          }
          .gwd-page-size {
            width: 60%;
            height: auto;
          }
        }
        @media (min-width: 680px)  and (max-width: 1023px) {
          padding: 100px 0 40px 0;
          .gwd-page-content {
            transform: scale(.8);
          }
          .gwd-page-size {
            width: 830px;
          }
        }
        @media all and (max-width: 679px) {
          padding: 110px 0 40px 0;
          .gwd-page-content {
            transform: scale(.5);
          }
          .gwd-page-size {
            width: 830px;
            height: 400px;
          }
          .wally_wrapper {
            margin-top: -120px;
          }
        }
        
        &-title {
          font-size: 60px;
          color: $blue;
          line-height: 1.1;
          margin-bottom: 60px;
          @media (min-width: 1024px) and (max-width: 1600px) {
            font-size: 44px;
            margin-bottom: 40px;
          }
          @media all and (max-width: 1023px) {
            font-size: 35px;
            margin-bottom: 30px;
          }
        }
        &-subtitle {
          font-size: 28px;
          line-height: 1.6;
          color: $black;
          margin-bottom: 75px;
          max-width: 550px;
          @media (min-width: 1024px) and (max-width: 1600px) {
            font-size: 24px;
            margin-bottom: 55px;
          }
          @media all and (max-width: 1023px) {
            font-size: 20px;
            margin-bottom: 30px;
            max-width: none;
          }
        }
      }
      .home_document {
        padding-top: 115px;
        @media all and (max-width: 1023px) {
          padding: 0 40px;
          flex-direction: column-reverse;
        }
        &-info {
          margin-top: 30px;
          @media all and (max-width: 1023px) {
            margin-bottom: 30px;
          }
        }
        .doc_center {
          text-align: center;
        }
        .document_invoice {
          max-width: 583px;
          @media all and (max-width: 1600px) {
            max-width: 80%;
          }
          @media all and (max-width: 1023px) {
            max-width: 60%;
          }
          @media all and (max-width: 679px) {
            max-width: 80%;
          }
        }
      }
      .home_api {
        padding: 125px 0;
        background-color: $light-blue;
          @media all and (max-width: 1023px) {
            padding: 50px 0;
            .code_preview {
              margin-top: 20px;
            }
          }
        
        &-info {
          max-width: 679px;
          margin-top: 50px;
          @media all and (max-width: 1023px) {
            max-width: none;
            margin-top: 0;
          }
        }
      }
      .home_automation {
        padding: 114px 0;
        @media all and (max-width: 576px) {
          div.gwd-page-size_automation {
            transform: scale(.38);
            height: 300px;
          }
          .w-60 {
            margin-top: -100px;
          }
        }
        @media all and (max-width: 1023px) {
          padding: 50px 0;
          .w-60 {
            width: 100%;
          }
          .w-40 {
            width: 100%;
          }
          .gwd-page-size_automation {
            width: 0;
            transform: scale(.65);
          }
        }
        @media (min-width: 1024px) and (max-width: 1600px) {
          padding: 60px 0;
          .gwd-page-size_automation {
            width: 0;
            transform: scale(.6);
          }
        }
      }
      .home_data {
        padding: 87px 0;
        background: $light-blue;
        @media all and (max-width: 1024px) {
          padding: 40px 0;
        }
        @media all and (max-width: 575px) {
          .gwd-page-size_data {
            transform: scale(.6);
            height: 388px;
          }
        }
        &-info {
          margin-top: 50px;
          @media all and (max-width: 1023px) {
            margin-top: 20px;
          }
        }
      }
      .home_internet {
        padding: 55px 0;
        @media (min-width: 576px) and (max-width: 1600px) {
          .gwd-page-size_thinks {
            transform: scale(.8);
          }
        }
        @media all and (max-width: 575px) {
          margin-top: -115px;
          .gwd-page-size_thinks {
            transform: scale(.5);
            height: 480px;
          }
        }
      }
      .home_partners {
        padding: 80px 0;
        background-color: $light-blue;
        @media all and (max-width: 1023px) {
          padding: 50px 0;
        }
        &-item {
          padding: 40px 15px;
          background-color: #fff;
          max-width: 245px;
          max-height: 175px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media all and (max-width: 1023px) {
            margin: 10px;
            padding: 25px 10px;
          }
          @media all and (max-width: 576px) {
            width: 80%;
            max-width: none;
          }
          svg {
            width: 70%;
            @media all and (max-width: 576px) {
              width: 50%;
            }
          }
        }
        .partners_title {
          margin-bottom: 25px;
          line-height: 1;
        }
        .partners_subtitle {
          margin-bottom: 35px;
        }
      }
      .home_footer {
        padding: 25px 0;
        background-color: #0a1d2c;
        text-align: center;
        @media all and (max-width: 576px) {
          .footer_wrapper {
            flex-wrap: wrap;
            .mail {
              margin-bottom: 10px;
            }
          }
        }
        a {
          font-size: 14px;
          text-decoration: none;
          font-weight: 300;
          color: #749fb2;
        }
        .mail {
          color: #fff;
        }
        address {
          color: #fff;
          margin: 0 10px 10px 0;
          font-size: 16px;
        }
      }
    }
  }
}