@import "../../css/css3-mixins";
@import "../../css/constants.scss";

/* ------------------------------------------------------------------------------------------------------------------ */


/* ---------------------------------------------- GH-ELEMENT -------------------------------------------------------- */


/* ------------------------------------------------------------------------------------------------------------------ */

div[gh-element] {
    @include display-flex();
    width: 100%;
    &>.action {
        margin: 5px;
    }
    span.field-name {
        @include css3-prefix( 'user-select', none);
        display: block;
        text-align: right;
        font-size: 12px;
        font-weight: 500;
        color: $dark-grey;
        text-overflow: ellipsis;
        padding: 14px 14px 0 0;
        min-width: 100px;
        max-width: 100px;
    }
    input,
    textarea {
        line-height: normal;
        @include transition( 'all .3s ease-in-out');
        box-shadow: none!important;
        border-bottom: 1px solid $grey;
    }
    input:focus,
    textarea:focus {
        @include box-shadow(0px, 0px, 10px, rgba(0, 0, 0, 0.1));
        border-color: $blue;
    }
    &[element-type="doc_template"],
    &[element-type="file"],
    &[element-type="image"],
    &[element-type="text_editor"],
    &[element-type="timeline"] {
        &>span {
            align-self: flex-start;
        }
    }
    /* -------- Aling content in conteiner ------- */
    &[data-position*='left'] {
        width: initial;
        @include flex-justify('start');
    }
    &[data-position*='right'] {
        width: initial;
        @include flex-justify('start');
    }
    &[data-position*='center'] {
        @include flex-justify('center');
    }
    &[data-position*='beetwen'] {
        &>*:not(span) {
            @include flex(1);
            text-alling: center;
        }
    }
    &[data-position*='full'] {
        width: 100%!important;
        gh-date-input {
            div[ng-switch-default],
            div[ng-switch-when="small"] {
                @include flex-justify('center');
            }
        }
        gh-color {
            &>div {
                &>.gh-color-item {
                    margin: 0 auto;
                }
            }
        }
        gh-file {
            .gh-file-list {
                margin: 0 auto;
            }
        }
        gh-file {
            .gh-file-list {
                margin: 0 auto;
            }
        }
        gh-image {
            .gh-image-list {
                margin: 0 auto;
                img {
                    width: auto;
                    min-height: 100%;
                    max-height: 100%;
                    max-width: none;
                }
            }
        }
    }
}

.field-wrap-name {
    padding-right: 14px;
    white-space: normal;
    position: relative;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    min-width: 100px;
    max-width: 100px;
    overflow: hidden;
    line-height: 14px;
    .gh_element_name {
        @include css3-prefix( 'user-select', none);
        padding-top: 14px;
        font-size: 12px;
        font-weight: 500;
        color: $dark-grey;
        display: inline-block;
        white-space: normal;
    }
}


/* ------------------------------------------------------------------------------------------------------------------ */


/* ---------------------------------------------- GH-TOOL-TIP -------------------------------------------------------- */


/* ------------------------------------------------------------------------------------------------------------------ */

gh-tool-tip {
    display: inline-block;
    text-align: left;
    margin-right: 5px;
    white-space: normal;
}

gh-element,
div[gh-element] {
    &:hover {
        .field-tooltip {
            .field-tooltip-icon {
                opacity: 1;
            }
        }
    }
    .field-tooltip {
        position: relative;
        &:hover {
            .field-tooltip-container {
                display: flex;
            }
        }
        .field-tooltip-icon {
            border-radius: 100%;
            width: 15px;
            height: 15px;
            padding-top: 2px;
            border: 1px solid $grey;
            color: $dark-grey;
            display: flex;
            font-size: 11px;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            opacity: 0;
            z-index: 999;
            position: relative;
            &:hover {
                border: 1px solid $light-blue;
                color: $blue;
            }
        }
        .field-tooltip-container {
            display: none;
            background-color: $pure-white;
            border: 1px solid $grey;
            box-shadow: 0 1px 2px 0 $grey;
            border-radius: 15px;
            padding: 15px;
            width: 320px;
            z-index: 998;
            max-height: 640px;
            font-size: 12px;
            font-weight: 300;
            color: $black;
            &:before {
                content: '';
                position: absolute;
                left: 16px;
                width: 10px;
                height: 10px;
                display: block;
                bottom: 17px;
                background-color: $pure-white;
                z-index: 1;
                border-width: 1px 1px 0 0;
                border-style: solid;
                border-color: $grey;
                transform: rotate(135deg);
            }
            h3 {
                font-size: 14px;
                font-weight: 500;
                color: $black;
                margin: 0;
                line-height: 1;
                border: none;
            }
            .field-tooltip-block {
                width: 100%;
                overflow-x: hidden;
                display: flex;
                justify-content: space-between;
                
                .field-tooltip-edit{
                    cursor: pointer;
                    display: flex;
                    align-items: flex-end;
                }
            }
        }
    }
}

.field-tooltip-wrap {
    position: fixed;
    transform: translate(-20px, calc(-100% + 3px));
    padding-bottom: 21px;
    z-index: 998;
}

//Update_sate
.update_check {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 10px;
    height:10px;
}

.update_check:before {
    content: "";
    height: 2px;
    border-radius: 2px;
    background: $green;
    transform: rotate(45deg);
    top: 6px;
    left: 1px;
    transition: width 50ms ease 50ms;
    transform-origin: 0% 0%;
    width: 5px;
    transition: width 100ms ease;
    opacity: 0;
    position: absolute;

}
.gh_element_name{
    width: 100%;
}

.update_check:after {
    content: "";
    height: 2px;
    border-radius: 2px;
    background: $green;
    transform: rotate(305deg);
    top: 10px;
    left: 1px;
    position: absolute;
    transition: width 50ms ease;
    transform-origin: 0% 0%;
    width: 10px;
    transition: width 150ms ease 100ms;
    opacity: 0;
}

.update_check.show_update {
    display: inline;
}

.update_check.show_update:before {
    opacity: 1;
}

.update_check.show_update:after {
    opacity: 1;
}

