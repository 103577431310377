@import "../../css/css3-mixins";
@import "../../css/constants.scss";

/* ------------------------------------------------------------------------------------------------------------------ */
/* ----------------------------------------------- RADIO BUTTON ----------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------------------------ */
gh-radio-button {
  display: inline-block;
  position: relative;
    padding: 14px 0 0;
    text-align: left;

    .gh-radio-button-item{
      color: $black;
      font-weight: 300;

      &:hover{
        border-color: $blue;
        color: $blue;
      }
    }
}
.gh-radio-button-dropdown {
    width: 100%;
    white-space: nowrap;
    font-size: 13px;
    color: #ffffff;
    padding: 5px;
    display: block;
    cursor: pointer;
}
.with-icon .gh-button{
    padding: 0;
    margin: 0;
    border: none;
    width: 100%;
    text-align: center;
  }
gh-radio-button .gh-radio-button-item:first-child{
    margin-left: 0;
}
.filter-panel gh-radio-button .gh-radio-button-item{
  margin: 0;
}
.gh_radio_button_one li.gh-radio-button-item{
  padding: 0;
}
.gh_radio_button_one .gh-radio-button-item{
  text-transform: uppercase;
  border-radius: 3px;
  padding: 0.4em 0.6em;
  font-weight: 500;
}

gh-radio-button.gh-field_value .gh-radio-button-item {
  color: $black!important;
}
gh-radio-button.gh-field_value .selected .gh-radio-button-item {
  color: $white!important;
}
.gh_radio_button_default{
  padding-top: 14px;
}

.filter-panel gh-radio-button{
  padding-top: 0;
}
